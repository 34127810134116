import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "description", "refundTotal", "adjustmentTotal", "fee"];

  declare itemTargets : HTMLInputElement[];
  declare descriptionTarget: HTMLInputElement;
  declare refundTotalTarget: HTMLSpanElement;
  declare adjustmentTotalTarget: HTMLSpanElement;
  declare feeTarget: HTMLInputElement;
  declare hasFeeTarget: boolean;

  updateDescriptionAndTotal(){
    let description = "";
    let total = 0;
    let subtotal = 0;

    const selectedItemTargets = this.itemTargets.filter((item) => item.checked);

    // Delivery Fee Refund
    if (this.hasFeeTarget && this.feeTarget?.checked && this.feeTarget?.dataset?.value) {
      total += parseFloat(this.feeTarget?.dataset?.value);
      description += "Refunded fees";
      if (selectedItemTargets.length > 0) { description += ", " }
    }

    // Order Items Refund
    selectedItemTargets.forEach ( (item, index) => {
      if (index == 0) { description += "Missing "; }
      else { description += ", " }

      description += item.dataset.description;

      if (item.dataset.value) { subtotal += parseFloat(item.dataset.value); }
    });

    total += subtotal;

    this.descriptionTarget.value = description;
    this.refundTotalTarget.innerHTML = total.toFixed(2);
    this.adjustmentTotalTarget.innerHTML = subtotal.toFixed(2);
  }
}
