import React, { useRef, useEffect } from "react";
import { Conversation } from "./conversation";
import { Client } from "./client";
import { Message } from "./message";
const MessageList = ({
  conversation,
  client,
}: {
  conversation: Conversation;
  client: Client;
}): JSX.Element => {
  // midnight is 4am
  const today = new Date();
  if (today.getHours() < 4)
    today.setHours(-24);
  today.setHours(4, 0, 0, 0);
  const todayFirstId = conversation.messages.find(m => (m.publishedAt || 0) >= today)?.messageID;

  const messageElements = conversation.messages.map((m) => {
    return <MessageItem message={m} client={client} key={m.messageID} todayFirstId={todayFirstId} />;
  });

  if (!todayFirstId)
    messageElements.push(<MessageSeparator key="separator:0" />)

  const endEl = useRef<HTMLSpanElement>(null);
  const lastMessageId = conversation.messages[conversation.messages.length - 1]?.messageID;

  useEffect(() => {
    if (endEl.current !== null) {
      endEl.current.scrollIntoView({
        behavior: "auto", // : 'smooth',
        block: "end",
      });
    }
  }, [
    // only scroll to the bottom when a new conversation is selected or a new message is received
    conversation.uuid, lastMessageId, conversation.messages.length
  ]);

  return (
    <>
      {messageElements}
      <span ref={endEl}></span>
    </>
  );
};

const MessageItem = ({
  message,
  client,
  todayFirstId,
}: {
  message: Message;
  client: Client;
  todayFirstId: string | undefined;
}) => {
  const capitalise = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const authorDetails = message.authorDetails;

  const role =
    authorDetails.authorType == "admin"
      ? capitalise(client.chatType)
      : "Driver";

  const author = client.findUser(
    authorDetails.authorUUID,
    authorDetails.authorType
  );

  const isAuthoredBySelf = authorDetails.authorUUID == client.uuid;

  const authorName =
    isAuthoredBySelf
      ? "You"
      : `${author?.name}` || "Unknown";

  const authorClassName = 
    isAuthoredBySelf
      ? "is-author"
      : authorDetails.authorType == "admin"
        ? "is-admin"
        : "";

  // publishedAt is only null for unsent messages, which shouldn't really ever be in
  // this message list set, but we have to handle this for now. Random string it is.
  const publishedAt =
    message.publishedAt?.toLocaleString("en-NZ", {
      timeStyle: "short",
      dateStyle: "short",
    }) || "Draft";

  const messageSeparator = message.messageID == todayFirstId ? <MessageSeparator key={"separator:" + message.messageID} /> : <></>;

  return (
    <>
      {messageSeparator}
      <div className="chat-message">
        <div className={`chat-message--body ${authorClassName}`}>
          {message.content}
        </div>

        <div className="chat-message--details">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item is-size-7">
                <span className="chat-message--name has-text-weight-medium">
                  {authorName}
                </span>

                {isAuthoredBySelf || (
                  <span className="is-uppercase chat-message--role">{role}</span>
                )}
              </div>

              <div className="level-item is-size-7 has-text-grey-light">
                <span className="chat-message--timestamp">{publishedAt}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function MessageSeparator() {
  return (
    <div className="chat-separator">
      Today
    </div>
  )
}

export { MessageList };
