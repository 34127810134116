import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "link"];

  declare buttonTarget: HTMLDivElement;
  declare linkTarget: HTMLAnchorElement;

  async removeAttachment() : Promise<void> {
    const path = this.buttonTarget.dataset["path"]
    if (path != undefined){
      await fetch(path, {
        credentials: 'same-origin',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      this.linkTarget.remove();
      this.buttonTarget.remove();
    }
  }
}
