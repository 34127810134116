import { Controller, ActionEvent } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";
import { decode } from "@googlemaps/polyline-codec";

interface SuburbListing {
  id: number;
  name: string;
  map_color_index?: number;
  boundaries?: string[];
}

export default class extends Controller {
  readonly UNSERVICED_FILL_COLOR = "rgb(0, 0, 0)";
  readonly SELECTED_UNSERVICED_FILL_COLOR = "rgb(100, 100, 100)";
  readonly NORMAL_FILL_COLORS = ["rgb(15, 130, 242)", "rgb(34, 171, 36)", "rgb(253, 140, 0)", "rgb(235, 71, 38)", "rgb(109, 59, 191)", "rgb(205, 14, 102)", "rgb(0, 158, 166)"]

  static targets = ["map"];

  declare mapTarget: HTMLElement;
  declare hasMapTarget: boolean;

  // we can't initialize any Maps objects until it's loaded, and we need to run the Loader to do that
  map?: google.maps.Map;

  loadDebounceTimer?: number;

  suburbPolygons = new Map<number, google.maps.Polygon>();

  async connect(): Promise<void> {
    const mapsKey = this.data.get("maps-key");
    if (!this.hasMapTarget || !mapsKey) return;
    await this.loadMaps(mapsKey);
    this.createMap();
    await this.loadData();
  }

  disconnect(): void {
    this.suburbPolygons.forEach((suburb) => suburb.setMap(null));
    this.suburbPolygons.clear();

    this.map = undefined;
  }

  protected async loadMaps(mapsApiKey: string): Promise<void> {
    const loader = new Loader({ apiKey: mapsApiKey, region: "NZ", language: "en" });
    await loader.load();
    await google.maps.importLibrary("places");
  }

  protected createMap(): void {
    this.map = new google.maps.Map(this.mapTarget, {
      center: { lat: -41.2529389, lng: 174.6842181 },
      zoom: 6,
      styles: [
        // turn off all markers to reduce clutter, particularly from irrelevant businesses,
        // then turn park names back on to help people orient
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [{ "visibility": "off" }]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels",
          "stylers": [{ "visibility": "on" }]
        },

        // make the map greyer so the parks etc. don't conflict with the suburb colors
        {
          "featureType": "poi",
          "stylers": [{ "saturation": -90 }]
        },
        {
          "featureType": "landscape.natural",
          "stylers": [{ "saturation": -90 }]
        },
        {
          "featureType": "water",
          "stylers": [{ "saturation": -60 }]
        },
      ]
    });
  }

  protected async loadData(): Promise<void> {
    const path = this.data.get("path");
    if (!path) return;

    const response = await fetch(path, { credentials: 'same-origin' });
    const json = await response.json();

    const bounds = new google.maps.LatLngBounds();

    for (const suburb of json.suburbs) {
      if (!this.suburbPolygons.has(suburb.id)) {
        const polygon = this.renderSuburb(suburb);
        this.suburbPolygons.set(suburb.id, polygon);
        polygon.getPaths().forEach((path) => path.forEach((latlng) => bounds.extend(latlng)));
      }
    }

    this.map?.fitBounds(bounds, 0);
  }

  protected decodePolyline(polyline: string): google.maps.LatLng[] {
    return decode(polyline).map((tuple) => new google.maps.LatLng(tuple[0], tuple[1]));
  }

  protected suburbFillColor(suburb: SuburbListing): string {
    const map_color_index = (suburb?.map_color_index ?? suburb.id) % this.NORMAL_FILL_COLORS.length;
    return this.NORMAL_FILL_COLORS[map_color_index];
  }

  protected renderSuburb(suburb: SuburbListing): google.maps.Polygon {
    const paths = suburb.boundaries?.map((polyline) => this.decodePolyline(polyline));
    const polygon = new google.maps.Polygon({
      map: this.map,
      paths: paths,
      fillColor: this.suburbFillColor(suburb),
      fillOpacity: 0.4,
      strokeOpacity: 0.2,
      strokeColor: "rgba(50, 50, 50)",
      strokeWeight: 1,
      clickable: false,
    });
    return polygon;
  }

  highlight(event: ActionEvent): void {
    const polygon = this.suburbPolygons.get(event.params["suburbId"]);
    if (polygon) polygon.setOptions({ fillOpacity: 0.6 });
  }

  unhighlight(event: ActionEvent): void {
    const polygon = this.suburbPolygons.get(event.params["suburbId"]);
    if (polygon) polygon.setOptions({ fillOpacity: 0.4 });
  }
}

export { SuburbListing };
