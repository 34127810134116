import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";
import { Loader } from "@googlemaps/js-api-loader";

export default class extends Controller {
  static targets = ["addressfinderPxid", "placeId", "latitude", "longitude"];
  static values = {
    inputAddress: String,
    addressFinderKey: String,
    mapsKey: String,
  }

  declare inputAddressValue: string;
  declare addressFinderKeyValue: string;
  declare mapsKeyValue: string;

  declare addressfinderPxidTarget: HTMLInputElement;
  declare placeIdTarget: HTMLInputElement;
  declare latitudeTarget: HTMLInputElement;
  declare longitudeTarget: HTMLInputElement;

  defaultBounds(): google.maps.LatLngBounds {
    return new google.maps.LatLngBounds(
      new google.maps.LatLng(-52.722466, 165.743764),
      new google.maps.LatLng(-28.877322, -175.123508),
    );
  }

  connect(): void {
    this.getAddressFinderPxid(this.inputAddressValue);
    this.getGooglePlaceId(this.inputAddressValue);
  }

  private async getGooglePlaceId(inputAddress: string): Promise<void> {
    const loader = new Loader({ apiKey: this.mapsKeyValue ?? "", region: "NZ", language: "en" });
    await loader.load();
    await google.maps.importLibrary("places");

    const autocomplete = new google.maps.places.AutocompleteService();

    autocomplete.getPlacePredictions({
      input: inputAddress,
      locationRestriction: this.defaultBounds(),
      componentRestrictions: {
        country: 'NZ',
      },
    }, (results, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK && results != null && results.length > 0) {
        this.placeIdTarget.value = results[0].place_id;
      }
    });
  }

  private async getAddressFinderPxid(inputAddress: string): Promise<void> {
    const resp = await fetch(`https://api.addressfinder.io/api/nz/address/verification/?key=${this.addressFinderKeyValue}&format=json&q=${encodeURIComponent(inputAddress)}`);
    const json = await resp.json();

    if (json['matched']) {
      this.addressfinderPxidTarget.value = json.pxid;

      // when AddressFinder *does* find the address, their geocoding is more reliable than Google's, so replace the form's default
      this.longitudeTarget.value = json.x;
      this.latitudeTarget.value = json.y;
    } else {
      Sentry.captureMessage(`AddressFinder: fetching addressfinder did not return a match`, { extra: {
        inputAddress: inputAddress,
        json: json
      } });
      return;
    }
  }
}
