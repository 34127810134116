import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() : void {
    window.addEventListener("click", (event) => {
      if ((event.target as HTMLElement).closest(".dropdown"))
        return;

      // In almost every case, there should only be one item in this, but there is no harm in cleaning up
      // all and it accounts for any weird situations.
      // We intentionally use a selector that includes this controller here, to avoid stomping on the older
      // drop down implementations which don't have good handling for suppressing global clicks inside their
      // content, like we do.
      document.querySelectorAll(`.dropdown.is-active[data-action*="dropdown#toggleDropdown"]`).forEach(el => el.classList.remove("is-active"));
    });
  }

  toggleDropdown(event: MouseEvent) : void {
    const dropdowns = document.querySelectorAll(".dropdown.is-active");
    const target = event.target as HTMLElement;
    const trigger = target.closest(".dropdown-trigger") as HTMLElement;
    const dropdownTarget = target.closest(".dropdown") as HTMLElement;

    // Hide all other drop downs
    dropdowns.forEach(tg => {
      if (dropdownTarget !== tg) {
        tg.classList.remove("is-active")
      }
    });

    if (trigger) {
      // Toggle active on selected drop down
      dropdownTarget.classList.toggle("is-active");
    }
  }
}
