import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dashNotes", "driverNotes"];

  declare dashNotesTarget : HTMLTextAreaElement;
  declare driverNotesTarget : HTMLTextAreaElement;

  populateDashNotes() : void {
    this.dashNotesTarget.value = this.driverNotesTarget.value;
  }
}
