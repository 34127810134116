import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";

import DriverTrackerMap from "src/admin/dispatch/driver_tracker_map";
import DispatchApiClient from "src/admin/dispatch/dispatch_api_client";
import { DriverTrackingUpdate } from "src/admin/dispatch/model";

import storeMarkerImage from 'images/admin/map/restaurant_pinlet-2-medium.png';
import addressMarkerIcon from "../../images/map/delivery-address-marker-icon.svg";
import dropoffMarkerIcon from "../../images/map/delivery-dropoff-marker-icon.svg";
import photoMarkerIcon from "../../images/map/delivery-photo-marker-icon.svg";

interface DeliveryLocation {
  lat: string;
  lng: string;
  label: string;
  icon: string;
  photo: string;
}

export default class extends Controller {
  static targets = ["map"];

  declare mapTarget: HTMLElement;
  declare hasMapTarget: boolean;

  readonly handleDriverTrackingUpdate = this.driverTrackingUpdated.bind(this);

  locations!: DeliveryLocation[];

  trackerMap?: DriverTrackerMap;
  client?: DispatchApiClient;
  areaId!: number;
  deliveryId!: number;

  async connect(): Promise<void> {
    const mapsKey = this.data.get("maps-key");
    if (!this.hasMapTarget || !mapsKey) return;
    const data = this.data.get("locations");
    this.locations = data ? JSON.parse(data) as DeliveryLocation[] : [];
    await this.loadMaps(mapsKey);
    this.createMap();
    this.areaId = parseInt(this.data.get("area-id") ?? "");
    this.deliveryId = parseInt(this.data.get("delivery-id") ?? "");
    if (this.data.get("live-tracking") == "true") this.startTracking();
  }

  private async loadMaps(mapsApiKey: string): Promise<void> {
    const loader = new Loader({ apiKey: mapsApiKey, region: "NZ", language: "en" });
    await loader.load();
  }

  private createMap(): void {
    this.trackerMap = new DriverTrackerMap(
      this.mapTarget,
      undefined,
      false,
      [],
    );

    const icons: Record<string, google.maps.Icon> = {
      "store": { url: storeMarkerImage, scaledSize: new google.maps.Size(17, 24) },
      "address": { url: addressMarkerIcon, scaledSize: new google.maps.Size(24, 24), anchor: new google.maps.Point(24/2, 24) },
      "dropoff": { url: dropoffMarkerIcon, scaledSize: new google.maps.Size(24, 24), anchor: new google.maps.Point(24/2, 24) },
      "photo": { url: photoMarkerIcon, scaledSize: new google.maps.Size(24, 24), anchor: new google.maps.Point(24/2, 24) },
    } as const;

    const bounds = new google.maps.LatLngBounds();

    for (const index in this.locations) {
      const location = this.locations[index];
      const position = {
        lat: Number(location.lat),
        lng: Number(location.lng),
      };
      bounds.extend(position);

      new google.maps.Marker({
        map: this.trackerMap.map,
        position: position,
        title: location.label,
        zIndex: Number(index) + 1,
        icon: icons[location.icon],
      });
    }

    this.trackerMap.map.fitBounds(bounds);
  }

  private startTracking() {
    const csrfToken = document.querySelector("[name='csrf-token']")?.getAttribute("content") || "";
    if (!csrfToken) return;

    this.client = new DispatchApiClient(this.areaId, csrfToken);
    this.client.driverTrackingUpdated.addListener(this.handleDriverTrackingUpdate);
    this.client.start();
  }

  driverTrackingUpdated(update: DriverTrackingUpdate) {
    const tracking = update.deliveryTrackingList.find((tracking) => tracking.id == this.deliveryId);

    if (tracking) {
      if (update.driverId != this.trackerMap?.focussedDriver) {
        // This update is from the driver now handling this delivery; add them to the map.
        this.trackerMap?.addDriverMarker(update.driverId);
        this.trackerMap?.focusDriver(update.driverId);
      }
      this.trackerMap?.handleDriverTrackingUpdate(update);
    } else {
      if (update.driverId == this.trackerMap?.focussedDriver) {
        // This update is from a driver who *was* handling this delivery, but it's not in their
        // tracking list any more, implying they've delivered it or it's been taken off them;
        // remove their marker.
        this.trackerMap?.focusDriver(undefined);
        this.trackerMap?.removeDriverMarker(update.driverId);
      }
    }
  }
}
