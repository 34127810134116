import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  displayConfirm(event: Event) : void {
    const button: HTMLInputElement = event.target as HTMLInputElement
    const message = button != null && button.dataset["confirmText"] ? button.dataset["confirmText"] : "Are you sure?"

    const response = confirm(message);
    if (response == true) {
      return;
    } else {
      event.preventDefault();
    }
  }
}
