import { decode } from "@googlemaps/polyline-codec";

export default class MapPath {
  points: google.maps.LatLng[];

  static decode(polylines: string[]): MapPath {
    const points = polylines.flatMap(polyline => decode(polyline)).map((tuple) => new google.maps.LatLng(tuple[0], tuple[1]));
    return new MapPath(points);
  }

  constructor(points: google.maps.LatLng[]) {
    this.points = points;
  }

  trim(position: google.maps.LatLng): google.maps.LatLng[] {
    // let start = window.performance.now();
    let closestIndex = -1;
    let closestPoint = null;
    let closestDistance = null;

    // First find the closest point on the route, looking firstly just at the actual points
    // returned in the polyline.
    for (let index = 0; index < this.points.length; index++) {
      const point = this.points[index];
      const distance = this.distanceBetween(point, position);

      if (closestDistance == null || distance <= closestDistance) {
        closestIndex = index;
        closestPoint = point;
        closestDistance = distance;
      }
    }

    // We can usually (but not always) do better than those points, because there's usually a
    // line segment between two of the points that passes closer. So we need to find the point
    // on each line segment that is closest to the point of interest, and see if that is closer.
    //
    // We use cartesian math to approximate rather than the much more complicated calculations
    // on the geodesic. This is fine for the scales we are working with here (which are each
    // fragments of a street), except in the respect that one unit of latitude is not the same
    // distance as one unit of longitude (especially so far from the equator). We work around
    // that by scaling the latitude down, making the imaginary unit grid roughly square locally.
    //
    // The cartesian maths is based on the idea of projecting the point down onto the line:
    // https://math.stackexchange.com/questions/2248617/shortest-distance-between-a-point-and-a-line-segment
    // (you don't need to understand the formula, just use it!). Note that since _our_ line
    // segments are all contiguous - the end of each one is the start of the next - we don't
    // need the steps to check both points when t is outside the range 0..1, which would mean
    // we'd check every point (except the very first and very last) twice; instead, we do the
    // extra loop above, even though in practice this loop will almost always do better.
    // Together, this whole algorithm executes in about 1ms for most routes, 5ms in the most
    // extreme case we've tested with over 1000 points.
    const latScale = Math.cos(position.lat()*Math.PI/180);
    for (let index = 0; index < this.points.length - 2; index++) {
      const a = this.points[index];
      const b = this.points[index + 1];
      const t = -((a.lng() - position.lng())*(b.lng() - a.lng()) + ((a.lat() - position.lat())/latScale)*((b.lat() - a.lat())/latScale))/
                  ((b.lng() - a.lng())**2 + ((b.lat() - a.lat())/latScale)**2);

      if (t >= 0 && t <= 1) {
        const point = new google.maps.LatLng(
          a.lat() + t*(b.lat() - a.lat()),
          a.lng() + t*(b.lng() - a.lng()));
        const distance = this.distanceBetween(point, position);

        if (closestDistance == null || distance <= closestDistance) {
          closestIndex = index;
          closestPoint = point; // this will then replace the point at points[index], as we want
          closestDistance = distance;
        }
      }
    }
    const trimmedRoute = this.points.slice(closestIndex);
    if (closestPoint != null) {
      trimmedRoute[0] = closestPoint;
    }

    // let end = window.performance.now();
    // console.log("closest line segment was " + closestIndex + "-" + (closestIndex + 1) + " out of " + this.points.length + " at " + closestDistance + "m, coords " + closestPoint + ", took " + (end - start) + " ms");

    return trimmedRoute;
  }

  private distanceBetween(p1: google.maps.LatLng, p2: google.maps.LatLng): number {
    const degreesToRadians = (degrees: number) => degrees*Math.PI/180;
    const hav = (radians: number) => Math.pow(Math.sin(radians/2), 2) // see https://en.wikipedia.org/wiki/Haversine_formula
    const earthRadius = 6378137; // metres, for compatibility with google maps (most sources use the more approximate 6371000)

    const lat1 = degreesToRadians(p1.lat());
    const lat2 = degreesToRadians(p2.lat());
    const lng1 = degreesToRadians(p1.lng());
    const lng2 = degreesToRadians(p2.lng());

    const a = hav(lat2 - lat1) + hav(lng2 - lng1)*Math.cos(lat1)*Math.cos(lat2);
    return 2*Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))*earthRadius;
  }
}
