import { Controller } from "@hotwired/stimulus";

type Event = {
  target: HTMLInputElement;
}

export default class extends Controller {
  static targets = [ "parent", "individual" ]

  declare parentTargets : HTMLInputElement[];
  declare individualTargets : HTMLInputElement[];

  toggleAll(event: Event) {
    const scopeIdentifier = event.target.dataset["bulkCheckboxScopeIdentifier"];
    const matchingTargets = this.individualTargets.filter((target) => target.dataset["bulkCheckboxScopeIdentifier"] == scopeIdentifier && target.checked != event.target.checked);
    matchingTargets.forEach((target) => target.checked = event.target.checked);
    matchingTargets.forEach((target) => target.dispatchEvent(new Event("change")));
  }

  toggleOne(event: Event) {
    const scopeIdentifier = event.target.dataset["bulkCheckboxScopeIdentifier"];
    const parent = this.parentTargets.find((target) => target.dataset["bulkCheckboxScopeIdentifier"] == scopeIdentifier);
    if (parent) {
      const matchingTargets = this.individualTargets.filter((target) => target.dataset["bulkCheckboxScopeIdentifier"] == scopeIdentifier);
      const allChecked = matchingTargets.every((target) => target.checked);

      if (parent.checked != allChecked) {
        parent.checked = allChecked;
      }
    }
  }
}
