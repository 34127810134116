import PubNub, {
  PubnubConfig,
  StatusEvent,
  PublishParameters,
  MessageEvent,
  SetMembershipsParameters,
} from "pubnub";

import { Conversation, HistoryStatus } from "./conversation";
import { Message } from "./message";
import { Driver } from "../dispatch/model";
import { DriverShiftStatus } from "../driver_shift_status";
import { JsonDecoder } from "ts.data.json";
import Signal from "../../signal";
import { adminCable } from "src/admin_cable";

export class Client {
  readonly conversationsUpdated = new Signal<Conversation[]>();

  constructor(
    sub: string,
    pub: string,
    uuid: string,
    ct: ChatType,
    cis: number[],
    rails_env: string,
    earliestUnreadsFrom: string | null,
  ) {
    this.subscribeKey = sub;
    this.publishKey = pub;
    this.uuid = uuid;
    this.chatType = ct;
    this.chatAreaIds = cis;
    this.earliestUnreadsFrom = earliestUnreadsFrom;
    this.parsedEarliestUnreadsFrom = parseFloat(earliestUnreadsFrom || "");

    this.environment =
      rails_env == "development" ? "development" : "production";

    this.start();
  }

  subscribeKey: string;
  publishKey: string;
  uuid: string;
  chatType: ChatType;
  chatAreaIds: number[];
  environment: string;
  earliestUnreadsFrom: string | null;
  parsedEarliestUnreadsFrom: number

  authKey: string | null = null;
  pubnub: PubNub | null = null;

  status: Status = Status.Connecting;
  reconnectAt: number | null = null;
  lastReconnectInterval = 1000;
  reconnectIntervalHandle: number | null = null;

  driverUpdateSubscriptions: ActionCable.Subscription[] = [];

  areaNames: Map<number, string> = new Map();
  conversations: Conversation[] = [];
  userMetadata: User[] = [];

  public markConnected() {
    this.changeStatus(Status.Connected);
    this.lastReconnectInterval = 1000;
    this.reconnectAt = null;

    this.resetConversationList();
  }

  public markDisconnected() {
    this.reconnectAt = Date.now() + this.lastReconnectInterval;

    this.changeStatus(Status.Disconnected);

    window.setInterval(() => {
      if (this.shouldTryReconnect()) {
        this.start();
      }
    }, 500);

    this.lastReconnectInterval = this.lastReconnectInterval * 2;
  }

  public async markReconnected() {
    await this.preloadUnreadCounts(this.conversations);
    await this.preloadConvosWithUnreads(this.conversations);
  }


  public async sendMessage(convo: Conversation, message: Message) {
    if (this.pubnub === null) {
      return;
    }

    message.attemptedSendAt = new Date();

    const encoded = Message.encode(message);

    let summaryMessage = "You've been sent a new message";
    switch (this.chatType) {
      case ChatType.Manager:
        summaryMessage = `An area coordinator has sent you a new message`;
        break;

      case ChatType.Dispatcher:
        summaryMessage = `A dispatcher has sent you a new message`;
        break;
    }

    let pushData = PubNub.notificationPayload("Chat", summaryMessage);

    // provide optional sound property
    // and other push properties that apply to both push services
    pushData.sound = "default";

    // add APNS2 specific properties
    pushData.apns.configurations = [
      {
        targets: [
          {
            topic: "nz.co.delivereasy.driver",
            environment:
              this.environment == "production" ? "production" : "development",
          },
        ],
      },
    ];

    // build the the push payload for APNS2 and FCM
    let pushPayload = pushData.buildPayload(["apns2", "fcm"]);

    Object.assign(encoded, pushPayload);

    const params: PublishParameters = {
      message: encoded,
      channel: convo.chatChannelName,
    };

    try {
      await this.pubnub.publish(params);
    } catch (err) {
      console.log("Message sending failed:", err);
    }
  }

  public async loadHistory(convos: Conversation[]) {
    if (this.pubnub === null) {
      return;
    }

    const convoNames = convos.map((convo) => convo.chatChannelName);

    convos.forEach((convo) => {
      convo.historyStatus = HistoryStatus.Loading;
    });
    const params = {
      channels: convoNames,
      count: 100,
      includeUUID: true,
      stringifiedTimeToken: true,
    };

    try {
      const resp: FetchMessagesResponse = await this.pubnub.fetchMessages(
        params
      );

      for (const channelName in resp.channels) {
        const channel = resp.channels[channelName];

        for (const msg of channel) {
          try {
            if (msg.uuid === undefined) {
              throw "Missing sender UUID on incoming message";
            }

            const parsedMessage = await Message.decode(
              msg.message,
              new Date((msg.timetoken as number) / 10000),
              msg.uuid
            );

            const convo = convos.find(
              (convo) => convo.chatChannelName === channelName
            );

            // If we get a message for a channel we're not tracking, find() above returns
            // undefined and we can just skip over.
            if (convo !== undefined) {
              // We skip updating the unreads count because we already preloaded it earlier.
              await this.ensureUserAndAddMessage(
                convo,
                parsedMessage,
                true
              );
            }
          } catch (_error) {
            // Silently ignore unparsable messages.
          }
        }
      }

      this.conversationsUpdated.notifyListeners(this.conversations);
    } catch (error) {
      console.error("Failed to fetch message history", error);
    }
  }

  public findUser(authorUUID: string, authorType: UserType): User | undefined {
    return this.userMetadata.find(
      (user) => user.pubnubUser == authorUUID && user.userType == authorType
    );
  }

  private async ensureUserAndAddMessage(
    convo: Conversation,
    message: Message,
    skipUnread: boolean,
  ) {
    if (!message.authorDetails) return;

    const user = this.findUser(
      message.authorDetails.authorUUID,
      message.authorDetails.authorType
    );

    if (user === undefined) {
      let newUser = await this.fetchUser(
        message.authorDetails.authorUUID,
        message.authorDetails.authorType
      );
      if (newUser != null) {
        this.userMetadata.push(newUser);
      }
    }

    convo.addMessage(message, skipUnread);
  }

  private async fetchUser(
    uuid: string,
    userType: UserType
  ): Promise<User | null> {
    const userDecoder = JsonDecoder.object<User>(
      {
        pubnubUser: JsonDecoder.constant(uuid),
        userType: JsonDecoder.constant(userType),
        name: JsonDecoder.string,
      },
      "User",
    );

    try {
      const resp = await fetch(
        `/api/v1/admin/chat/user_metadata/${userType}/${uuid}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (resp.status == 404) {
        return null;
      }

      const json = await resp.json();
      const user = await userDecoder.decodeToPromise(json);

      return user;
    } catch (_e) {
      if (_e instanceof Error)
        this.emitError(
          `An unexpected error has occured, let the tech team know. This error is: ${_e.message}`
        );
      else throw _e;
    }
  }

  private shouldTryReconnect(): boolean {
    return (
      this.status == Status.Disconnected &&
      (this.reconnectAt === null || Date.now() > this.reconnectAt)
    );
  }

  private async start() {
    this.changeStatus(Status.Connecting);
    this.subscribeToDriverStatusUpdates();
    this.authKey = await this.fetchAuthKey();
    this.setupPubnub();
  }

  private changeStatus(newStatus: Status) {
    this.status = newStatus;
  }

  private async resetConversationList() {
    this.conversations = [];

    // We trigger a lot of re-renders in here because it makes the page
    // feel a lot more interactive, a lot faster. It's a bit overkill, and
    // we can probably get rid of most of these once more of the application
    // is using react state.

    await Promise.all(
      this.chatAreaIds.map(async (id) => {
        await this.resetConversationListForArea(id);
      })
    );
    this.conversationsUpdated.notifyListeners(this.conversations);

    await this.preloadUnreadCounts(this.conversations);

    this.conversationsUpdated.notifyListeners(this.conversations);

    await this.preloadConvosWithUnreads(this.conversations);

    this.conversationsUpdated.notifyListeners(this.conversations);
  }

  private async resetConversationListForArea(chatId: number) {
    try {
      const resp = await fetch(
        `/api/v1/admin/chat/conversations/${this.chatType}/${chatId}`,
        {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const json = await resp.json();

      this.areaNames.set(chatId, json["areaName"]);

      if (json["conversations"] instanceof Array) {

        const newConvos = json["conversations"].map(
          (c) =>
            new Conversation(
              c["id"],
              c["uuid"],
              c["name"],
              c["mobileNumber"],
              c["internalNote"] || "",
              this.chatChannelFor(c["uuid"]),
              c["status"],
              c["areaId"],
              json["areaName"]
            )
        );
        this.conversations = this.conversations.concat(newConvos);
      } else {
        this.emitError("Unable to load conversations list");
      }
    } catch (_e) {
      if (_e instanceof Error)
        this.emitError(
          `An unexpected error has occured, let the tech team know. This error is: ${_e.message}`
        );
      else throw _e;
    }
  }

  chatChannelFor(uuid: string): string {
    return `driver-${this.normalisedChatType()}.${uuid}`;
  }

  normalisedChatType(): string {
    if (this.chatType == ChatType.Dispatcher) {
      return "dispatch";
    } else if (this.chatType == ChatType.Manager) {
      return "management";
    } else {
      this.emitError("Unknown chat type");
    }
  }

  async preloadUnreadCounts(forConversations: Conversation[]): Promise<any[]> {
    if (this.pubnub === null) {
      return Promise.reject();
    }

    const options: PubNub.GetMembershipsParametersv2 = {
      uuid: this.uuid,
      include: {
        customFields: true,
      },
    };

    const recordCounts = async (channels: string[], tokens: string[]) => {
      const counts = await this.pubnub!.messageCounts({
        channels: channels,
        channelTimetokens: tokens,
      });
      Object.entries(counts.channels).forEach((k) => {
        const convo = forConversations.find((c) => c.chatChannelName == k[0]);
        if (convo !== undefined) {
          convo.unreadMessages = k[1];
        } // We can just ignore failures here :)
      });
    };

    let timestampData: PubNub.ChannelMembershipObject<
      CustomMembershipData,
      Record<string, never>
    >[] = [];
    let nextPageKey;

    // This for loop is really an infinite loop is disguise, but
    // tslint _hates_ constant loop expressions, so I just loop a
    // bunch of times instead.
    for (let i = 0; i < 1000; i++) {
      if (nextPageKey) options.page = { next: nextPageKey };

      const membershipData = await this.pubnub.objects.getMemberships<
        CustomMembershipData, Record<string,string>
      >(options);
      timestampData = timestampData.concat(membershipData.data);

      if (nextPageKey == membershipData.next) break;
      nextPageKey = membershipData.next;
    }

    const channelsInOrder: string[] = [];
    const timeTokensInOrder: string[] = [];
    const parsedEarliestUnreadsFrom = this.parsedEarliestUnreadsFrom;
    let futures = forConversations
      .map((c) => {
        channelsInOrder.push(c.chatChannelName);

        const data = timestampData.find(
          (md) => md.channel.id == c.chatChannelName
        );

        let timeToken;
        if (data) {
          if (parsedEarliestUnreadsFrom > data.custom!.lastReadTimetoken) {
            timeToken = parsedEarliestUnreadsFrom;
          } else {
            timeToken = data.custom!.lastReadTimetoken;
          }
        } else {
          if (isNaN(parsedEarliestUnreadsFrom)) {
            timeToken = 1;
          } else {
            timeToken = parsedEarliestUnreadsFrom;
          }
        }

        timeTokensInOrder.push(timeToken.toString());

        // There is a limit of 100 channels in the messageCounts pubnub call
        if (channelsInOrder.length >= 100) {
          const channels = channelsInOrder.splice(0, 100);
          const tokens = timeTokensInOrder.splice(0, 100);
          return recordCounts(channels, tokens);
        }

        return null;
      })
      .filter((f) => f != null);

    // unless channelsInOrder is a multiple of 100, we'll still have some to fetch
    if (channelsInOrder.length > 0)
      futures.push(recordCounts(channelsInOrder, timeTokensInOrder));

    return Promise.all(futures);
  }

  async preloadConvosWithUnreads(forConversations: Conversation[]) {
    if (this.pubnub === null) {
      return;
    }

    const channelsWithUnreads = forConversations.filter(
      (convo) => convo.unreadMessages > 0
    );

    let futures = [];

    while (channelsWithUnreads.length > 0) {
      futures.push(this.loadHistory(channelsWithUnreads.splice(0, 100)));
    }

    await Promise.all(futures);
  }

  private async fetchAuthKey(): Promise<string> {
    try {
      const resp = await fetch("/api/v1/admin/chat/auth_key", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await resp.json();

      if (json["authKey"] !== null) {
        return json["authKey"];
      } else {
        this.emitError("Missing authentication key data");
      }
    } catch (_e) {
      if (_e instanceof Error)
        this.emitError(
          `An unexpected error has occured, let the tech team know. This error is: ${_e.message}`
        );
      else throw _e;
    }
  }

  private async setupPubnub() {
    const conf: PubnubConfig = {
      subscribeKey: this.subscribeKey,
      publishKey: this.publishKey,
      authKey: this.authKey!,
      uuid: this.uuid,
      ssl: true,
      restore: true,
    };

    this.pubnub = new PubNub(conf);

    this.pubnub.addListener({
      status: this.onStatusChanged.bind(this),
      message: this.onMessage.bind(this),
    });

    if (this.chatType == ChatType.Manager) {
      this.pubnub.subscribe({ channels: ["driver-management.*"] });
    } else {
      this.pubnub.subscribe({ channels: ["driver-dispatch.*"] });
    }
  }

  async onMessage(messageEvent: MessageEvent) {
    const conversation = this.conversations.find((convo) => {
      if (convo.chatChannelName == messageEvent.channel) {
        return true;
      }
    });

    if (conversation === undefined) {
      // It's much easier for us to silently ignore messages from cities
      // we're not currently interested in. Alternates are:
      // * Subscribing to each driver channel individually (oof). We previously
      //   did this but it a little frustrating to implement convo list live
      //   updating. It also means we have to subscribe to hundrds of channels
      //   in some cases, which is dramatically more than the pubnub recommendation
      // * Putting the areaID of the driver in the channel, which makes
      //   changing areas difficult
      // * Using pubnub channel groups, which requires maintaining the channel
      //   groups on the server.
      // This 'drop unrelated messages' approach has a scalability downside, but
      // we believe chat in general from a business process will become unscalabe
      // before this tactic stops working.
      return;
    }

    try {
      const parsedMessaged = await Message.decode(
        messageEvent.message,
        new Date(parseInt(messageEvent.timetoken) / 10000),
        messageEvent.publisher
      );

      await this.ensureUserAndAddMessage(
        conversation,
        parsedMessaged,
        false
      );

      if (conversation.isActiveConversation) {
        this.setReadCursor(conversation);
      } else {
        this.conversationsUpdated.notifyListeners(this.conversations);
      }
    } catch (error) {
      console.error("Couldn't parse incoming message:", error);
    }
  }

  async onStatusChanged(statusEvent: StatusEvent) {
    switch (statusEvent.category) {
      case PubNub.CATEGORIES.PNAccessDeniedCategory:
        this.markDisconnected();
        break;

      case PubNub.CATEGORIES.PNNetworkUpCategory:
        this.markReconnected();
        break;

      case PubNub.CATEGORIES.PNConnectedCategory:
        this.markConnected();
        break;

      default:
        console.log("Unhandled status event: ", statusEvent);
    }
  }

  async setReadCursor(convo: Conversation) {
    if (this.pubnub === null) {
      return;
    }

    const time = (await this.pubnub.time()).timetoken;

    const params: SetMembershipsParameters<CustomMembershipData> = {
      channels: [
        {
          id: convo.chatChannelName,
          custom: {
            lastReadTimetoken: time,
          },
        },
      ],
    };

    this.pubnub.objects.setMemberships(params);

    convo.unreadMessages = 0;
    this.conversationsUpdated.notifyListeners(this.conversations);
  }

  emitError(msg: string): never {
    throw new ChatRuntimeException(msg);
  }

  /*
    ActionCable updates
  */
  subscribeToDriverStatusUpdates() {
    const cable = adminCable();
    if (!cable) {
      console.error("ActionCable client not found");
      return;
    }

    const handleDriverUpdate = this.onDriverUpdatesChannelData.bind(this);
    const mixin = {
      received(data: any) { handleDriverUpdate(data); }
    };
    this.chatAreaIds.forEach((id) => {
      const subscription = cable.subscriptions.create({ channel: "DriverUpdatesChannel", area_id: id }, mixin);
      this.driverUpdateSubscriptions.push(subscription);
    });
  }

  private async onDriverUpdatesChannelData(data: any) {
    if (!this.conversations) return;

    const updatedDriver = await Driver.decoder.decodePromise(data["driver"]);
    const areaName = this.areaNames.get(updatedDriver.areaId);
    const conversation = this.conversations.find((c) => c.uuid == updatedDriver.pubnubUuid);

    if (updatedDriver.status == DriverShiftStatus.Removed || areaName == undefined) {
      // The driver has been removed from our selected areas or from the system as a whole, remove them from the conversation list
      const index = conversation ? this.conversations.indexOf(conversation) : -1;
      if (index > -1) this.conversations.splice(index, 1);
    } else if (conversation) {
      // The driver is already in our conversation list, update the details
      conversation.driverShiftStatus = updatedDriver.status;
      conversation.mobileNumber = updatedDriver.mobileNumber;
      conversation.internalNote = updatedDriver.internalNote;
      conversation.areaId = updatedDriver.areaId;
      conversation.areaName = areaName;
    } else {
      // The driver isn't in our conversation list, add them
      const conversation = new Conversation(
        updatedDriver.id,
        updatedDriver.pubnubUuid,
        updatedDriver.name,
        updatedDriver.mobileNumber,
        updatedDriver.internalNote,
        this.chatChannelFor(updatedDriver.pubnubUuid),
        updatedDriver.status,
        updatedDriver.areaId,
        areaName,
      );
      this.conversations.push(conversation);

      await this.preloadUnreadCounts([conversation]);
      await this.preloadConvosWithUnreads([conversation]);
    }

    this.conversationsUpdated.notifyListeners(this.conversations);
  }
}

class ChatRuntimeException extends Error {
  constructor(msg: string) {
    super(msg);
  }
}

interface FetchMessagesResponse {
  channels: {
    [channel: string]: Array<{
      channel: string;
      message: any;
      timetoken: string | number;
      meta?: {
        [key: string]: any;
      };
      uuid?: string;
      actions: {
        [type: string]: {
          [value: string]: Array<{
            uuid: string;
            actionTimetoken: string | number; // timetoken
          }>;
        };
      };
    }>;
  };
}

export enum Status {
  Connecting,
  Connected,
  Disconnected,
}

export enum ChatType {
  Dispatcher = "dispatcher",
  Manager = "manager",
}

export enum UserType {
  Admin = "admin",
  Driver = "driver",
}

export class User {
  pubnubUser: string;
  userType: UserType;
  name: string;

  constructor(
    uuid: string,
    userType: UserType,
    name: string,
  ) {
    this.pubnubUser = uuid;
    this.userType = userType;
    this.name = name;
  }

  public static mapUserType(userType: string): UserType {
    switch (userType) {
      case "admin":
        return UserType.Admin;
      case "driver":
        return UserType.Driver;
      default:
        throw `Unknown user type ${userType}`;
    }
  }
}

interface CustomMembershipData {
  [key: string]: any;
  lastReadTimetoken: number;
}
