import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["details"];

  declare detailsTarget : HTMLInputElement;

  prepare_details(event: Event) : void {
    const element = event.target;
    if (element instanceof HTMLSelectElement && element.selectedOptions[0]?.value == "other") {
      this.detailsTarget.removeAttribute("disabled");
    } else {
      this.detailsTarget.setAttribute("disabled", "true");
    }
  }
}
