import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";
import { GoogleAutocompleteResolver } from "../../src/google_autocomplete_resolver";

export default class StoresController extends Controller {
  static targets = ["storeAvailabilities", "addressInput", "addressResult", "addressLatitude", "addressLongitude"]

  declare storeAvailabilitiesTarget : HTMLButtonElement;
  declare hasAddressInputTarget: boolean;
  declare addressInputTarget: HTMLInputElement;
  declare addressResultTarget: HTMLInputElement;
  declare addressLatitudeTarget: HTMLInputElement;
  declare addressLongitudeTarget: HTMLInputElement;

  addressAutocomplete?: google.maps.places.Autocomplete;

  async connect(): Promise<void> {
    const mapsKey = this.data.get("maps-key");
    if (mapsKey && this.hasAddressInputTarget) {
      await this.loadMaps(mapsKey);
      this.initAutocomplete();
    }
  }

  toggleAvailabilities() : void {
    this.storeAvailabilitiesTarget.classList.toggle("is-hidden");
  }

  private async loadMaps(mapsApiKey: string): Promise<void> {
    const loader = new Loader({ apiKey: mapsApiKey, region: "NZ", language: "en" });
    await loader.load();
    await google.maps.importLibrary("places");
  }

  private initAutocomplete(): void {
    if (this.hasAddressInputTarget && this.addressInputTarget) {
      this.addressAutocomplete = new google.maps.places.Autocomplete(this.addressInputTarget, {
        types: ['address'], // "return only geocoding results with a precise address"
        fields: ['formatted_address', 'geometry'],
        bounds: GoogleAutocompleteResolver.defaultBounds(),
        strictBounds: true,
      });

      this.addressAutocomplete.addListener("place_changed", this.placeChanged.bind(this));
    }
  }

  async placeChanged(): Promise<void> {
    if (this.hasAddressInputTarget && this.addressInputTarget && this.addressAutocomplete) { // always true if this method is called
      const place = this.addressAutocomplete.getPlace();
      if (place && place.formatted_address && place.geometry?.location) {
        // We have a separate field for what we actually submit in params because we want to make sure that the
        // submitted address_for_maps corresponds to the submitted latitude & longitude; putting the autocomplete
        // on an address_for_maps input itself nearly works but unfortunately then if the admin doesn't actually
        // choose from the autocomplete list, the place doesn't get updated. We'd rather lose the incomplete
        // address change than have not-matching data.
        this.addressResultTarget.value = place.formatted_address;
        this.addressLatitudeTarget.value = place.geometry.location.lat().toString();
        this.addressLongitudeTarget.value = place.geometry.location.lng().toString();

        // We also update the input field to the "full" version of the selected value, which has the city etc. in it,
        // because the default behavior is just to select the short version which isn't what we'll actually use.
        this.addressInputTarget.value = place.formatted_address;
      }
    }
  }
}
