import React from "react";

import { Driver, DeliveryGroup } from "./model";

interface DeliveryGroupProps {
  groups: DeliveryGroup[];
  allowUngroup?: boolean;
  isUnallocatedOrder: boolean;
  onConfirm: (groupId: number) => Promise<void>;
}

interface DeliveryGroupState {
  selectedDeliveryGroupId: number;
  sending: boolean;
}

class DeliveryGroupSelect extends React.PureComponent<DeliveryGroupProps, DeliveryGroupState> {
  state: Readonly<DeliveryGroupState> = {
    selectedDeliveryGroupId: -2,
    sending: false,
  };

  async handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    this.setState({
      sending: true
    });

		try {
			await this.props.onConfirm(this.state.selectedDeliveryGroupId);
		} catch {
		} finally {
			this.setState({
				sending: false
			});
		}
  }


  handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ selectedDeliveryGroupId: parseInt(event.target.value), sending: false });
  }

  render() {
    const optionNodes = this.props.groups.map(group => {
      return (<option value={group.id} key={group.id}>{group.groupNumber}</option>)
    });

    const classes = this.state.sending ? "is-loading" : "";
    const nilState = <option value={"-2"}>{this.props.isUnallocatedOrder ? "->" : "-"}</option>
    const ungroup = this.props.allowUngroup ?
    <option value={"-1"}>Ungroup</option> : <></>;

    return (
      <div className="field has-addons" data-nodnd={true}>
        <div className="control is-expanded">
          <span className="select is-large-control is-small is-fullwidth is-radiusless">
            <select className="is-radiusless pl-1" onChange={this.handleChange.bind(this)}>
              {nilState}
              {ungroup}
              {optionNodes}
            </select>
          </span>
        </div>

        <div className="control">
          <button className={`button is-large-control is-small ${classes}`} disabled={this.state.sending} onClick={this.handleClick.bind(this)}>
            <span className="icon">
              <i className="fas fa-caret-right"></i>
            </span>
          </button>
        </div>
      </div>
    );
  }
}

interface DriverSelectProps {
  drivers: Driver[];
  allowUnallocate?: boolean;
  onConfirm: Function;
};

interface DriverSelectState {
  selectedDriverId: number;
  sending: boolean;
};

class DriverSelect extends React.PureComponent<DriverSelectProps, DriverSelectState> {
  state: Readonly<DriverSelectState> = {
    selectedDriverId: -2,
    sending: false,
  };

  static defaultProps = {
    allowUnallocate: true,
  };

  async handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (this.state.selectedDriverId == -2) return;

    this.setState({
      sending: true
    });

		try {
			await this.props.onConfirm(this.state.selectedDriverId);
		} catch {
		} finally {
			this.setState({
				sending: false
			});
		}
  }

  handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ selectedDriverId: parseInt(event.target.value), sending: false });
  }

  render() {
    const optionNodes = this.props.drivers.map(driver => {
      const label = driver.internalNote == "" ? driver.name : `${driver.name} (!\u20DD ${driver.internalNote})`;
      return <option key={driver.id} value={driver.id}>{label}</option>
    });

    const classes = this.state.sending ? "is-loading" : "";
    const nilState = <option value={"-2"}>-</option>
    const unallocate = this.props.allowUnallocate ? 
      <option value={"-1"}>none</option> : <></>;

    return (
      <form>
        <div className="field has-addons" data-nodnd={true}>
          <div className="control is-expanded is-radiusless">
            <span className="select is-large-control is-small is-fullwidth is-radiusless">
              <select className="is-radiusless pl-1" value={this.state.selectedDriverId} onChange={this.handleChange.bind(this)}>
                {nilState}
                {unallocate}
                {optionNodes}
              </select>
            </span>
          </div>

          <div className="control">
            <button className={`button is-large-control is-small ${classes}`} disabled={this.state.sending} onClick={this.handleClick.bind(this)}>
              <span className="icon">
                <i className="fas fa-caret-right"></i>
              </span>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export { DeliveryGroupSelect, DriverSelect };
