import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener("turbo:morph", this.morph);
    this.morph();
  }

  disconnect() {
    window.removeEventListener("turbo:morph", this.morph);
  }

  morph() {
    const textarea = document.getElementById("shift_report_general_notes");
    if (textarea) {
      textarea.addEventListener("input", () => {
        // set to 1px so the textarea properly resizes when the height reduces
        textarea.style.height = "1px";
        textarea.style.height = textarea.scrollHeight + "px";
      })
      textarea.dispatchEvent(new Event("input"));
    }
  }

  toggleUnresolvedNotes() {
    const unresolvedNotes = Array.from(document.getElementsByClassName('block is-success'));
    for (let note of unresolvedNotes) {
        note.classList.toggle("is-hidden");
    }
  }
}
