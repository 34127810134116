import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "person", "search", "hidden"];

  declare selectTarget: HTMLInputElement;
  declare personTarget: HTMLInputElement;
  declare searchTarget: HTMLInputElement;
  declare hiddenTarget: HTMLInputElement;

  hadInput = false;

  connect(): void {
    if (this.selectTarget.value != '') {
      this.personTarget.classList.add('is-hidden');
    }
  }

  selected(): void {
    this.hadInput = false;
    this.hiddenTarget.value = this.selectTarget.value;

    if (this.selectTarget.value == '') {
      this.personTarget.classList.remove('is-hidden');
      this.searchTarget.value = '';
      this.searchTarget.focus();
    } else {
      this.personTarget.classList.add('is-hidden');
    }

    // For consistency with the autocomplete controller, fire an onchange event on the hidden field input:
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("change", true, true);
    this.hiddenTarget.dispatchEvent(evt);
  }

  search_input(): void {
    this.hadInput = true;
  }

  search_change(ev: Event): void {
    if (!ev.isTrusted) {
      // the autocomplete code has sent a change event, so the user has selected from the autocomplete
      // list, and the text input and hidden field now match up
      this.hadInput = false;
    } else if (this.hadInput) {
      // if we had an oninput event and hadInput was not then cleared above, that means this was a
      // normal input edit + blur, not an autocomplete selection; we don't have any way to map manual
      // edits to IDs (only the autocomplete code knows how to query & select), so pessimistically
      // clear the hidden field, and clear the input too to make it clear what we did.
      this.searchTarget.value = '';
      this.hiddenTarget.value = '';
    }
  }

  focus_click(ev: MouseEvent & { target: HTMLInputElement }): void {
    ev.preventDefault();

    ev.target.focus();
    ev.target.select();
  }
}
