import { JsonDecoder } from "ts.data.json";
import { v4 as uuidv4 } from "uuid";
import { UserType } from "./client";

export type ISO8601 = string;
export type UUID = string;

export class AuthorDetails {
  authorUUID: UUID;
  authorType: UserType;

  constructor(_authorUUID: UUID, _authorType: UserType) {
    this.authorUUID = _authorUUID;
    this.authorType = _authorType;
  }
}

type MessageProto = Partial<
  Omit<Message & AuthorDetails, "authorDetails" | "sortingProperty">
> & { content: string; authorUUID: UUID; authorType: UserType };

export class Message {
  content: string;

  authorDetails: AuthorDetails;
  attemptedSendAt?: Date;
  publishedAt?: Date;
  messageID: UUID;

  constructor({
    content,
    authorUUID,
    authorType,
    attemptedSendAt,
    publishedAt,
    messageID,
  }: MessageProto) {
    this.content = content;
    this.attemptedSendAt = attemptedSendAt;
    this.publishedAt = publishedAt;

    if (messageID === null || messageID == undefined) {
      this.messageID = uuidv4();
    } else {
      this.messageID = messageID;
    }

    if (!authorUUID || !authorType) {
      throw "Missing author information";
    }

    this.authorDetails = new AuthorDetails(authorUUID, authorType);
  }

  sortingProperty(): Date {
    return this.publishedAt || this.attemptedSendAt || new Date();
  }

  static decode(json: any, publishedAt: Date, authorUUID: UUID): Promise<Message> {
    return messageDecoder(publishedAt, authorUUID).decodeToPromise(json);
  }

  static encode(message: Message): Object {
    const out: { [key: string]: any } = {};

    out["content"] = message.content;
    out["messageID"] = message.messageID;
    out["authorType"] = message.authorDetails.authorType;
    out["attemptedSendAt"] = message.attemptedSendAt;

    return out;
  }
}

const messageDecoder = (publishedAt: Date, authorUUID: UUID) =>
  JsonDecoder.object<MessageProto>(
    {
      content: JsonDecoder.string,
      messageID: JsonDecoder.string,
      authorType: JsonDecoder.enumeration(UserType, "UserType"),
      authorUUID: JsonDecoder.constant(authorUUID),
      attemptedSendAt: JsonDecoder.optional(JsonDecoder.string.map((d) => new Date(d))),
      publishedAt: JsonDecoder.constant(publishedAt),
    },
    "MessageProto"
  ).map((m) => new Message(m));
