import { Controller } from "@hotwired/stimulus";

interface HTMLFileUploadElement extends HTMLInputElement{
  files: FileList;
}

export default class extends Controller {
  static targets = ["fileUpload", "fileList", "filename", "attachmentPreview"]

  declare fileUploadTarget: HTMLFileUploadElement;
  declare filenameTarget: HTMLSpanElement;
  declare hasFileUploadTarget: boolean;
  declare attachmentPreviewTarget: HTMLDivElement;
  declare hasAttachmentPreviewTarget: boolean;

  updatePreview(event: Event) : void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const fileInputNames = Array.from(fileInput.files).map(file => {
        return file.name;
      }).join(", ");
      this.filenameTarget.innerText = fileInputNames

      // Remove existing attachment previews if new ones are uploaded
      if (this.hasAttachmentPreviewTarget) {
        this.attachmentPreviewTarget.remove();
      }
    }
  }

  validateUploadedFiles(event: Event): void {
    if (!this.hasFileUploadTarget) {
      event.preventDefault();
      throw "No fileUpload target defined"
    }

    this.clearErrors();

    if (!this.validateFileTypes() || !this.validateFileSize()) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
  /*
    Validate each file that has been uploaded.
    Types of validations:
    - FileSize: Provide the `data-file-upload-max-file-size` attribute on the same element that the controller is defined. File size in bytes. eg: 50 or 3.4
    - FileType: Provide the `data-file-upload-required-file-types` attribute on the same element that the controller is defined. eg: ["application/pdf", "image/png"]
   */

  validateFileTypes(): boolean {
    const requiredFileTypesJson = this.data.get("requiredFileTypes");
    if (!requiredFileTypesJson) { return true; }
    const requiredFileTypes: string[] = JSON.parse(requiredFileTypesJson);

    const errorMessages: string[] = [];
    Array.from(this.fileUploadTarget.files).forEach(file => {
      if (!requiredFileTypes.includes(file.type)) { errorMessages.push(`${file.name} is not a valid file type.`) }
    });

    if (errorMessages.length != 0) {
      this.fileUploadTarget.scrollIntoView({ behavior: "smooth", block: "center" } );
      this.displayErrors(errorMessages)
      return false;
    }

    return true;
  }

  validateFileSize(): boolean {
    const maxFileSizeString = this.data.get("maxFileSize");
    if (!maxFileSizeString) { return true; }
    const maxFileSize = parseFloat(maxFileSizeString);

    if (Number.isNaN(maxFileSize)) { throw "Invalid Max File Size"; }

    const errorMessages: string[] = [];
    Array.from(this.fileUploadTarget.files).forEach(file => {
      if (file.size > maxFileSize) { errorMessages.push(`${file.name} can't be larger than ${maxFileSize} bytes.`) }
    });

    if (errorMessages.length != 0) {
      this.fileUploadTarget.scrollIntoView({ behavior: "smooth", block: "center" } );
      this.displayErrors(errorMessages)
      return false;
    }

    return true;
  }

  displayErrors(messages: string[]): void {
    let errorHTML = ""
    messages.forEach((message) => { errorHTML += `<p class='help has-text-danger'>${message}</p>`})

    this.fileUploadTarget.insertAdjacentHTML("afterend", errorHTML);
  }

  clearElementsByClassName(classNames: string): void {
    if (!this.fileUploadTarget.parentElement) return;

    const elements = this.fileUploadTarget.parentElement.getElementsByClassName(classNames);
    if (elements != null){
      while (elements.length > 0){
        if (elements[0].parentNode != null)
          elements[0].parentNode.removeChild(elements[0]);
      }
    }
  }

  clearErrors(): void {
    this.clearElementsByClassName("help has-text-danger");
  }

  clearFileNames(): void {
    this.clearElementsByClassName("uploaded-file-name");
  }
}
