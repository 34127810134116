interface ModalProperties {
  title: string | Node;
  content: string | Node;
  acceptButtonLabel?: string;
  cancelButtonLabel?: string;
  onAccept?: (arg0: HTMLElement) => Promise<boolean | void>;
}

export default class Modal {
  static show(modalProperties: ModalProperties): Promise<HTMLElement> {
    return new Promise((resolve, reject) => {
      const body = document.getElementsByTagName("body")[0];
      const rootElement = document.createElement("div");

      rootElement.innerHTML = `
        <div class="modal is-active" data-controller="form-validator">
          <div class="modal-background"></div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title"></p>
              <span><button class="delete" aria-label="close"></button></span>
            </header>
            <section class="modal-card-body"></section>

            <footer class="modal-card-foot is-justify-content-space-between">
              <span><button data-action="click->form-validator#validateFields" class="button is-primary">${modalProperties.acceptButtonLabel ?? 'Save'}</button></span>
              <span><button class="button">${modalProperties.cancelButtonLabel ?? 'Cancel'}</button></span>
            </footer>
          </div>
        </div>`;

      const title = rootElement.getElementsByTagName("p")[0];
      const section = rootElement.getElementsByTagName("section")[0];
      const buttons = Array.from(rootElement.getElementsByTagName("button"));
      const acceptButton = buttons.find((el) => el.classList.contains("is-primary"));

      this.setContent(title, modalProperties.title);
      this.setContent(section, modalProperties.content);

      const cancelModal = () => {
        reject("Cancelled");
        body.removeChild(rootElement);
      }
      const acceptModal = async () => {
        const content = section.children[0] as HTMLElement;

        if (modalProperties.onAccept != undefined) {
          acceptButton?.classList.add("is-loading");
          try {
            if (await modalProperties.onAccept(content) === false) return;
          } finally {
            acceptButton?.classList.remove("is-loading");
          }
        }

        resolve(content);
        body.removeChild(rootElement);
      }

      for (const el of buttons) {
        // Attach our callbacks to the buttons. Unfortunately because Stimulus doesn't find out about
        // these new elements until the mutation observer does an async callback, if we add the event
        // listeners to the button elements themselves our close code would run before form-validator
        // and it won't get a chance to do its thing. So, we've added some dummy span elements around
        // the buttons, and we attach the click handlers to those instead.
        if (el == acceptButton) {
          el.parentNode?.addEventListener("click", acceptModal);
        } else {
          el.parentNode?.addEventListener("click", cancelModal);
        }
      }

      body.appendChild(rootElement);
    });
  }

  static setContent(element: HTMLElement, content: string | Node): void {
    if (content instanceof Node) {
      element.appendChild(content);
    } else {
      element.innerHTML = content;
    }
  }
}
