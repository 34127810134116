import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar", "submitButton", "dropdown", "sidebarTitle", "sidebarContent"];

  declare sidebarTarget : HTMLDivElement;
  declare submitButtonTarget: HTMLButtonElement;
  declare dropdownTarget: HTMLDivElement;
  declare hasDropdownTarget: boolean
  declare sidebarTitleTarget: HTMLElement;
  declare sidebarContentTargets: HTMLDivElement [];

  toggleDropdown(event: Event) : void {
    event.stopPropagation();

    this.dropdownTarget.classList.toggle("is-active");
  }

  dismissDropdown() : void {
    if (this.hasDropdownTarget === true) {
      this.dropdownTarget.classList.remove("is-active");
    }
  }

  openSidebar(event: Event) : void {
    const element = event.target;

    this.sidebarTarget.classList.remove("is-collapsed");
    this.sidebarContentTargets.forEach(tg => tg.classList.add("is-hidden"));

    if (element != null && element instanceof HTMLElement) {
      const sidebarId = element.dataset["sidebarId"] || ""
      document.getElementById(sidebarId)?.classList.remove("is-hidden");
    }
  }

  closeSidebar(event: Event) : void {
    event.preventDefault();

    this.sidebarTarget.classList.add("is-collapsed");
    this.sidebarContentTargets.forEach(tg => tg.classList.add("is-hidden"));
  }

  showFilters() : void {
    this.sidebarTarget.classList.remove("is-collapsed-touch");
  }
}
