import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["areaRestricted", "areaRestrictedOptions", "areaRestrictedOption", "suburbRestricted", "suburbRestrictedOptions"];

  declare hasAreaRestrictedTarget: boolean;
  declare hasSuburbRestrictedTarget: boolean;
  declare areaRestrictedTarget: HTMLInputElement;
  declare areaRestrictedOptionsTargets: HTMLElement[];
  declare areaRestrictedOptionTargets: HTMLInputElement[];
  declare suburbRestrictedTarget: HTMLInputElement;
  declare suburbRestrictedOptionsTargets: HTMLElement[];

  connect(): void {
    if (this.hasAreaRestrictedTarget && this.hasSuburbRestrictedTarget) {
      this.applyLocationFilters();
    }
  }

  applyLocationFilters(): void {
    if (this.areaRestrictedTarget.checked) {
      for (const child of this.areaRestrictedOptionsTargets) child.classList.remove('is-hidden');

      const areaIds = this.suburbRestrictedTarget.checked ? this.areaRestrictedOptionTargets.filter((child) => child.checked).map((child) => child.dataset["areaId"]) : [];

      for (const child of this.suburbRestrictedOptionsTargets) {
        if (child.dataset["areaId"] == undefined || areaIds.includes(child.dataset["areaId"])) {
          child.classList.remove('is-hidden');
        } else {
          child.classList.add('is-hidden');
        }
      }
    } else {
      for (const child of this.areaRestrictedOptionsTargets) child.classList.add('is-hidden');

      this.suburbRestrictedTarget.checked = false;
      for (const child of this.suburbRestrictedOptionsTargets) child.classList.add('is-hidden');
    }
  }
}
