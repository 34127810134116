export enum DeliveryStatus {
  Cancelled = "cancelled",
  Pending = "pending",
  Requested = "requested",
  Grouped = "grouped",
  Assigned = "assigned",
  PickupLater = "pickup_later",
  PickupNow = "pickup_now",
  PickupNearby = "pickup_nearby",
  PickupArriving = "pickup_arriving",
  PickupArrived = "pickup_arrived",
  PickupComplete = "pickup_complete",
  DropoffReassigned = "dropoff_reassigned",
  DropoffLater = "dropoff_later",
  DropoffNow = "dropoff_now",
  DropoffArriving = "dropoff_arriving",
  DropoffComplete = "dropoff_complete",
  ReturnLater = "return_later",
  ReturnNow = "return_now",
  ReturnComplete = "return_complete",
}
