import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";

import storeMarkerIcon from "../../images/map/restaurant-marker-icon.svg";

export default class extends Controller {
  static targets = ["map"];

  declare mapTarget: HTMLElement;
  declare hasMapTarget: boolean;

  map?: google.maps.Map;

  async connect(): Promise<void> {
    const mapsKey = this.data.get("maps-key");
    if (!this.hasMapTarget || !mapsKey) return;
    await this.loadMaps(mapsKey);
    this.createMap();
  }

  disconnect() {
    this.map = undefined;
  }

  private async loadMaps(mapsApiKey: string): Promise<void> {
    const loader = new Loader({ apiKey: mapsApiKey, region: "NZ", language: "en" });
    await loader.load();
  }

  private createMap(): void {
    const storeLocation = { lat: Number(this.data.get("latitude")), lng: Number(this.data.get("longitude")) };
    const contourColors = ["#5e4fa2", "#3288bd", "#66c2a5", "#abdda4", "#e6f598", "#fee08b", "#fdae61", "#f46d43", "#d53e4f", "#9e0142"];

    this.map = new google.maps.Map(this.mapTarget, {
      center: storeLocation,
      zoom: 16,
      maxZoom: 20,
      tilt: 0,
      styles: [
        // turn off all markers to reduce clutter, particularly from irrelevant businesses,
        // then turn park names back on to help people orient
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [{ "visibility": "off" }]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels",
          "stylers": [{ "visibility": "on" }]
        },

        // make the map greyer so the parks etc. don't conflict with the choropleth colors
        {
          "featureType": "poi",
          "stylers": [{ "saturation": -90 }]
        },
        {
          "featureType": "landscape.natural",
          "stylers": [{ "saturation": -90 }]
        },
        {
          "featureType": "water",
          "stylers": [{ "saturation": -60 }]
        },
      ]
    });

    const maxRange = this.data.get("max-range") == "" ? null : Number(this.data.get("max-range"));

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(storeLocation);

    const iconSize = 32;
    new google.maps.Marker({
      map: this.map,
      position: storeLocation,
      title: this.data.get("name") ?? "Store",
      icon: {
        url: storeMarkerIcon,
        scaledSize: new google.maps.Size(iconSize, iconSize),
        anchor: new google.maps.Point(iconSize/2, iconSize),
      },
    });

    this.map.data.setStyle((feature) => {
      if (feature.getProperty("contour")) {
        const contour = Number(feature.getProperty("contour"));
        const km = contour/1000;
        const color = contourColors[10 - km]
        return {
          fillOpacity: maxRange && contour <= maxRange ? 0.6 : 0.4,
          fillColor: color,
          strokeWeight: 0,
        };
      } else {
        return {
          fillOpacity: 0,
          strokeWeight: 2,
          strokeOpacity: 0.8,
        }
      }
    });

    const boundaryFeatures = this.map.data.addGeoJson(JSON.parse(this.data.get("boundary") ?? ""));

    boundaryFeatures.forEach((feature) => {
      feature.getGeometry()?.forEachLatLng((latlng) => {
        bounds.extend(latlng);
      });
    });

    this.map.fitBounds(bounds);

    this.map.data.addGeoJson(JSON.parse(this.data.get("contours") ?? ""));
  }
}
