import { Controller } from "@hotwired/stimulus";

export default class TabsController extends Controller {
  static targets = ["tab", "content"];

  declare tabTargets : HTMLLinkElement[];
  declare contentTargets : HTMLDivElement[];

  switch(ev: Event) : void {
    if (ev.currentTarget instanceof HTMLElement) {
      const tabIndex = ev.currentTarget.getAttribute("data-tab-index");

      for (const el of this.tabTargets)
        el.classList.remove("is-active");

      for (const el of this.contentTargets)
        el.classList.add("is-hidden");

      const finder = (el : HTMLElement) => el.getAttribute("data-tab-index") == tabIndex;

      const tabEl = this.tabTargets.find(finder);
      const contentEl = this.contentTargets.find(finder);

      tabEl?.classList.add("is-active");
      contentEl?.classList.remove("is-hidden");
    }
  }
}
