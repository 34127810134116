import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "mobileMenuToggle", "navItems", "navCategory", "navCategoryButton",
    "navCategoryDropdown", "navCategorySubmenu", "sideBarMenu"
  ];

  declare mobileMenuToggleTarget : HTMLElement;
  declare navItemsTarget : HTMLElement;
  declare sideBarMenuTarget : HTMLElement;

  declare navCategoryTargets : HTMLElement[];
  declare navCategoryButtonTargets : HTMLElement[];
  declare navCategoryDropdownTargets : HTMLElement[];
  declare navCategorySubmenuTargets : HTMLElement[];

  openDropdown?: HTMLElement;

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();

    const target = event.target as HTMLElement;
    const dropdownTarget = target.closest(".dropdown") as HTMLElement;

    dropdownTarget.classList.toggle("is-active");
  }

  hideOpenDropdownOnDefocus(event: MouseEvent) {
    const target = event.target as HTMLElement;
    this.openDropdown = document.querySelector(".dropdown.is-active") as HTMLElement || document.querySelector(".has-dropdown.is-active") as HTMLElement || undefined;

    if (!this.openDropdown?.contains(target)) {
      this.openDropdown?.classList.remove("is-active");
    }
  }

  showCategorySubmenu(event: MouseEvent) {
    if (!this.sideBarMenuTarget.classList.contains('is-hidden-touch')) {
      event.preventDefault();

      const target = event.currentTarget as HTMLElement;
      const submenuTarget = target.parentElement?.nextElementSibling as HTMLElement;
      const navCategoryDropdownTarget = submenuTarget.previousElementSibling?.firstElementChild as HTMLElement;

      this.navCategorySubmenuTargets.forEach(element => {
        if (!element.classList.contains("is-hidden")) {
          element.classList.add("is-hidden");
        }
      });

      this.navCategoryButtonTargets.forEach(element => {
        if (element.classList.contains("is-active")) {
          element.classList.remove("is-active");
        }
      });

      submenuTarget.classList.remove("is-hidden");
      navCategoryDropdownTarget.classList.add("is-active");
    }
  }

  showSidebarDropdown(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;
    const buttonTarget = target.querySelector(".button") as HTMLElement;
    const dropdownTarget = target.querySelector(".dropdown-menu") as HTMLElement;
    const htmlTarget = document.querySelector("html") as HTMLElement;

    if (buttonTarget.classList.contains("is-active") && !htmlTarget.classList.contains("has-collapsed-nav")) {
      dropdownTarget.classList.add("is-hidden");
    } else {
      dropdownTarget.classList.remove("is-hidden");
    }

    let navItemsPadding = parseInt(window.getComputedStyle(this.navItemsTarget, null).getPropertyValue('padding-left'), 10);
    let dropdownPadding = parseInt(window.getComputedStyle(dropdownTarget, null).getPropertyValue('padding-top'), 10);

    let categoryOffsetTop = target.offsetTop;
    let categoryOffsetRight = this.navItemsTarget.offsetWidth - target.offsetWidth - navItemsPadding;

    let scrollOffset = this.navItemsTarget.scrollTop;

    dropdownTarget.style.top = `${categoryOffsetTop - dropdownPadding - scrollOffset}px`;
    dropdownTarget.style.left = `calc(100% - ${categoryOffsetRight}px)`;
    dropdownTarget.style.paddingLeft = `${categoryOffsetRight + navItemsPadding}px`;
  }

  toggleMobileMenu(_event: MouseEvent) {
    this.mobileMenuToggleTarget.classList.toggle("is-active");
    this.sideBarMenuTarget.classList.toggle("is-hidden-touch");
  }
}
