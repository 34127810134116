import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";

export default class RestaurantSuburbsController extends Controller {
  static targets = ["enabledRestaurantSuburb", "disabledRestaurantSuburb"]

  declare enabledRestaurantSuburbTargets : HTMLElement[];
  declare disabledRestaurantSuburbTargets : HTMLElement[];

  setVisibility(event: Event) : void {
    const element = event.currentTarget as HTMLElement;

    const { restaurantSuburbsUpdatePath: url, restaurantSuburbsSuburbVisibility: newVisibility, suburbId: suburbId } = element.dataset;

    if (url == undefined || newVisibility == undefined || suburbId == undefined) {
      throw "Controller not configured";
    }

    if (newVisibility === "true") {
      this.hideRestaurantSuburb(this.disabledRestaurantSuburbTargets, suburbId);
      this.updateRestaurantSuburb(url, true);
      this.revealRestaurantSuburb(this.enabledRestaurantSuburbTargets, suburbId);
    } else {
      this.hideRestaurantSuburb(this.enabledRestaurantSuburbTargets, suburbId);
      this.updateRestaurantSuburb(url, false);
      this.revealRestaurantSuburb(this.disabledRestaurantSuburbTargets, suburbId);
    }
  }

  private hideRestaurantSuburb(targets: HTMLElement[], suburbId: string) {
    targets.forEach(t => {
      if (t.dataset.suburbId == suburbId) {
        t.classList.add("is-hidden");
      }
    });
  }

  private revealRestaurantSuburb(targets: HTMLElement[], suburbId: string) {
    targets.forEach(t => {
      if (t.dataset.suburbId == suburbId) {
        t.classList.remove("is-hidden");
      }
    });
  }

  private async updateRestaurantSuburb(url: string, enable: boolean) {
    const bodyContent = {
      "enable": String(enable)
    }

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;

    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }

    const csrfToken = metaElement.content;

    const result = await fetch(url, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accepts": "application/json",
        "X-CSRF-Token": csrfToken,
        "X-Requested-With": "XMLHttpRequest" // Mimic JQuery to match `request.xhr?`
      },
      body: JSON.stringify(bodyContent)
    });

    if (result.status != 200) {
      const errorMessage = await result.text();
      Sentry.captureMessage(errorMessage);
    }
  }
}
