import { Controller, ActionEvent } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "driverExtendedInfo", "rosterLastHeader", "sortableButton", "rosterRow" ];
  declare driverExtendedInfoTargets: HTMLElement[];
  declare rosterLastHeaderTarget: HTMLDivElement;
  declare sortableButtonTargets: HTMLButtonElement[];
  declare rosterRowTargets: HTMLDivElement[];

  toggleExtendedDriverInfo() {
    this.driverExtendedInfoTargets.forEach(n => n.classList.toggle("is-hidden"));
  }

  checkAllShiftsForDriver(e: MouseEvent) {
    const target = e.target as HTMLDivElement;
    const row = target.closest(".roster-row");
    if (!row)
      return
    const requestedShifts = Array.from(row.getElementsByClassName("requested-driver-shift"));

    requestedShifts.forEach((n: Element) => {
      const checkbox = n.querySelector("[type=checkbox][form=confirm-checked-shifts]") as HTMLInputElement;
      checkbox.checked = true;
    });
  }

  sortPlanner(e: ActionEvent) {
    const index = e.params.index;

    this.sortableButtonTargets.forEach(button => button.classList.remove("has-background-dark", "has-text-light"));
    this.sortableButtonTargets[index].classList.add("has-background-dark", "has-text-light");

    // Sort by driver name
    if (index == 0) {
      this.rosterRowTargets
        .sort((a, b) => {
          const aName = a.dataset.name || "";
          const bName = b.dataset.name || "";
          return bName.localeCompare(aName);
        })
        .forEach(row => this.rosterLastHeaderTarget.after(row));
    }

    // Sort by shifts
    else {
      const rowsWithChangeRequested: HTMLDivElement[] = [];
      const rowsWithRequested: HTMLDivElement[] = [];
      const rowsWithConfirmed: HTMLDivElement[] = [];
      const rowsWithRemoved: HTMLDivElement[] = [];
      const rowsWithAway: HTMLDivElement[] = [];

      this.rosterRowTargets.forEach(row => {
        const shifts = row.children[index];

        if (shifts.getElementsByClassName("change-requested-driver-shift").length)
          rowsWithChangeRequested.push(row);
        else if (shifts.getElementsByClassName("requested-driver-shift").length)
          rowsWithRequested.push(row);
        else if (shifts.getElementsByClassName("confirmed-driver-shift").length)
          rowsWithConfirmed.push(row);
        else if (shifts.getElementsByClassName("removed-driver-shift").length)
          rowsWithRemoved.push(row);
        else if (shifts.getElementsByClassName("away-driver-shift").length)
          rowsWithAway.push(row);
      });

      const rowsInOrder = [
        ... rowsWithChangeRequested,
        ... rowsWithRequested,
        ... rowsWithConfirmed,
        ... rowsWithRemoved,
        ... rowsWithAway,
      ].reverse();

      rowsInOrder.forEach(row => this.rosterLastHeaderTarget.after(row));
    }
  }
}
