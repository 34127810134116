import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "toggle"]

  declare selectTarget: HTMLSelectElement;
  declare toggleTargets: HTMLElement[];

  connect() {
    const selectedValue = this.selectTarget.value;

    this.hideAll()
    this.show(selectedValue);
  }

  toggle(e: ActionEvent) {
    const selectedOption = e.currentTarget as HTMLOptionElement | null;

    if (selectedOption) {
      this.hideAll()
      this.show(selectedOption.value);
    }
  }

  private hideAll() {
    this.toggleTargets.forEach((target) => {
      target.classList.add("is-hidden")
    });
  }

  private show(selectedValue: string) {
    const relevantOption = this.toggleTargets.find((target) => target.dataset.option === selectedValue);

    relevantOption?.classList?.remove("is-hidden");
  }
}
