import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "image", "container"];

  declare modalTarget : HTMLElement;
  declare imageTarget : HTMLImageElement;
  declare containerTarget : HTMLElement;

  displayImageModal(event: Event) : void {
    event.stopPropagation();

    const imageElement = event.currentTarget as HTMLElement
    const url = imageElement.dataset["url"]

    if (url != undefined){
      this.imageTarget.src = url;
      this.modalTarget.classList.add("is-active");
    }
  }

  closeImageModal() : void {
    this.modalTarget.classList.remove("is-active");
  }

  toggleImages(): void {
    this.containerTarget.classList.toggle("is-hidden");
  }

  confirmRemove(event: Event): void {
    const response = confirm("Are you sure you want to remove this image?");
    if (response == true) {
      return;
    } else {
      event.preventDefault();
    }
  }
}
