import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown"];

  declare dropdownTarget: HTMLDivElement;
  declare hasDropdownTarget: boolean

  toggleDropdown() : void {
    this.dropdownTarget.classList.toggle("is-active");
  }
}
