import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "actionButton"];

  declare formTarget : HTMLFormElement;
  declare actionButtonTargets : HTMLInputElement[];

  connect(): void {
    this.formTarget.addEventListener('change', () => {
      this.actionButtonTargets.forEach((button: HTMLInputElement) => {
        button.disabled = true;
      });
    });
  }
}
