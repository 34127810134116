import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";

import DriverTrackerMap from "src/admin/dispatch/driver_tracker_map";
import DispatchApiClient from "src/admin/dispatch/dispatch_api_client";

export default class extends Controller {
  static targets = ["map", "lastUpdateTime"];

  declare mapTarget: HTMLElement;
  declare hasMapTarget: boolean;

  trackerMap?: DriverTrackerMap;
  clients: DispatchApiClient[] = [];

  async connect() {
    if (!this.hasMapTarget) return;

    const mapsApiKey = this.data.get("maps-key") ?? "";
    const areas = (this.data.get("area-ids") ?? "").split(",").map(s => parseInt(s));
    const stores = JSON.parse(this.data.get("stores") ?? "");
    const csrfToken = document.querySelector("[name='csrf-token']")?.getAttribute("content") || "";

    const loader = new Loader({ apiKey: mapsApiKey, region: "NZ", language: "en" });
    await loader.load();

    this.trackerMap = new DriverTrackerMap(
      this.mapTarget,
      undefined,
      this.data.get("maps-compact") != undefined,
      stores,
    );

    this.clients = areas.map(areaId => new DispatchApiClient(areaId, csrfToken));

    for (const client of this.clients) {
      client.dashboardUpdated.addListener(this.trackerMap.handleDashboardUpdate);
      client.driverTrackingUpdated.addListener(this.trackerMap.handleDriverTrackingUpdate);
      client.start();
    }
  }

  disconnect() {
    if (!this.trackerMap) return;

    for (const client of this.clients) {
      client.stop();
      client.driverTrackingUpdated.removeListener(this.trackerMap.handleDriverTrackingUpdate);
      client.dashboardUpdated.removeListener(this.trackerMap.handleDashboardUpdate);
    }

    this.trackerMap.dispose();
  }

  focusZone(event: Event) {
    const element = event.target;
    if (element instanceof HTMLElement && this.trackerMap) {
      const wasSelected = element.parentElement?.querySelector(".is-selected");
      if (wasSelected != null) {
        wasSelected.classList.remove("is-info");
        wasSelected.classList.remove("is-selected");
      }
      element.classList.add("is-info");
      element.classList.add("is-selected");

      const pickupZoneId = element.dataset.pickupZone ? parseInt(element.dataset.pickupZone) : undefined;
      const includeDrivers = !!element.dataset.includeDrivers;
      this.trackerMap.focusZone(pickupZoneId, includeDrivers);
    }
  }
}
