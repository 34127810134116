import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar"];

  declare sidebarTarget : HTMLElement;

  showFilters() {
    this.sidebarTarget.classList.remove("is-collapsed-touch");
  }

  applyFilters() {
    this.sidebarTarget.classList.add("is-collapsed-touch");
  }

  async setRestaurantActive(event: MouseEvent) {
    event.preventDefault();

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }
    const csrfToken = metaElement.content;
    const button = event.currentTarget as HTMLElement;
    const listItem = button.closest(".list-item") as HTMLElement;
    const inactiveBadge = listItem.querySelector(".tag.inactive") as HTMLElement;

    if (confirm("Are you sure you want to set " + button.getAttribute('data-restaurant-name') + " active?")) {
      const path = button.getAttribute('data-path');
      if ( path != undefined ) {
        const response = await fetch(path, {
          method: "POST",
          body: null,
          credentials: "include",
          headers: { "X-CSRF-Token": csrfToken, "Content-Type": "application/json" }
        });

        if (response.ok) {
          button.remove();
          inactiveBadge.remove();
        } else {
          const { errors } = await response.json()
          alert("Could not set restaurant as active due to error: " + errors);
        }
      }
    }
  }
}
