import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  confirmRemoveCategory(event: MouseEvent) : void {
    const r = confirm("Are you sure you want to remove this category?");
    if (r == false) {
      event.preventDefault();
    }
  }
}
