import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "preview"];

  declare sourceTarget: HTMLTextAreaElement;
  declare previewTarget: HTMLElement;

  bounceTimeout : number | null = null;

  beginPreview() {
    if (this.bounceTimeout != null) {
      window.clearTimeout(this.bounceTimeout);
    }

    this.bounceTimeout = window.setTimeout(this.fetchPreview.bind(this), 1000);
  }

  async fetchPreview() {
    const body = {
      sourceContent: this.sourceTarget.value
    };

    const response = await fetch("/admin/markdown_preview", {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    const json = await response.json();

    this.previewTarget.innerHTML = json["preview"];
  }
}
