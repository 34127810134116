import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["optionSet"];

  declare optionSetTargets: HTMLDivElement[];

  toggleCollapse(event: Event): void {
    const checkbox = event.currentTarget as HTMLInputElement

    const index = this.optionSetTargets.findIndex(t => t.getAttribute("data-option-set-id") === checkbox.getAttribute("data-option-set-id"));
    const optionSetTarget = this.optionSetTargets[index];

    if (checkbox.checked) {
      optionSetTarget.classList.add("is-hidden")
    } else {
      optionSetTarget.classList.remove("is-hidden")
    }

  }
}
