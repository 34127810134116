import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "value" ];
  declare valueTarget: HTMLInputElement;

  setValueAmount(e: MouseEvent) {
    const target = e.target as HTMLButtonElement;
    this.valueTarget.value = target.dataset.value || "";
  }
}
