import { Controller } from "@hotwired/stimulus";
import "amazon-connect-streams";

import { adminPhonesCheckUserPath, adminOrderPath, adminPhonesUpdateRoutingProfilePath, adminPhonesUpdateConnectContactAttributesPath } from "src/routes";

type PhoneWidgetMessage =
  | { type: "startNotifyingOfCall" }
  | { type: "stopNotifyingOfCall" }
  | { type: "initiateCall"; phNumber: string; orderID: string | null }


class TypedBroadcastChannel<T extends Record<string, unknown>> extends BroadcastChannel {
  constructor(name: string) {
    super(name);
  }

  public postMessage(message: T): void {
    super.postMessage(message);
  }
}

export default class PhoneWidgetController extends Controller {
  static targets = [
    "widget", "spinner", "widgetToggle", "widgetToggleIcon",
    "citiesToggle", "agentStatusToggle", "updateRoutingButton", "routingArea",
    "availability", "orderInfoIcon", "orderInfo", "dedicated"
  ];

  declare widgetTarget: HTMLElement;
  declare spinnerTarget: HTMLElement;
  declare widgetToggleTarget: HTMLElement;
  declare hasWidgetToggleIconTarget: boolean;
  declare widgetToggleIconTarget: HTMLElement;
  declare citiesToggleTarget: HTMLElement;
  declare agentStatusToggleTarget: HTMLElement;
  declare updateRoutingButtonTarget: HTMLButtonElement;
  declare routingAreaTargets: HTMLInputElement[];
  declare availabilityTarget: HTMLSpanElement;
  declare orderInfoIconTarget: HTMLLinkElement;
  declare dedicatedTarget: HTMLInputElement;

  broadcastChannel?: TypedBroadcastChannel<PhoneWidgetMessage>;
  enabled: boolean = false;
  phoneAgent?: connect.Agent;
  currentOrderID?: string | null;

  connect() {
    this.stopNotifyingOfCall();

    this.broadcastChannel = new TypedBroadcastChannel("phone_widget");
    this.broadcastChannel.onmessage = this.onBroadcastMessage.bind(this);
    this.currentOrderID = null;
  }

  initIfRequired() {
    if (this.phoneAgent === undefined) {
      navigator.mediaDevices?.getUserMedia({ audio: true })
        .catch(error => alert("Mic error:\r\n" + error));
      this.initWidget();
    }
  }

  showPhoneWidgetDropdown() {
    this.widgetToggleTarget.classList.add("is-active");
  }

  ignoreClick(e: MouseEvent) {
    e.stopPropagation();

    return false;
  }

  async updateRouting(e: InputEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.updateRoutingButtonTarget.classList.add("is-loading");

    const desiredRoutingCities = this.routingAreaTargets.filter(el => el.checked).map(el => el.value);
    const dedicated = this.dedicatedTarget.checked;

    const resp = await fetch(adminPhonesUpdateRoutingProfilePath(), {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ desiredRoutingCities: desiredRoutingCities, dedicated: dedicated }),
    });

    if (resp.status > 299) {
      throw "couldn't ensure user configuration";
    }

    const data = await resp.json();
    const activeQueues: number[] = data["current_routing_profile_cities"]
    const activeDedicated = data["dedicated"] === true;
    this.updateRoutingCities(activeQueues, activeDedicated);

    this.updateRoutingButtonTarget.classList.remove("is-loading");
  }

  async initWidget() {
    await this.ensureValidUser();
    this.initCCP();
  }

  initCCP() {
    const opts = {
      ccpUrl: "https://delivereasy-saml2.my.connect.aws/ccp-v2/",
      loginUrl: "https://d-9767052d74.awsapps.com/start/#/saml/default/Phones/ins-2a815958aff9d192",
      loginPopupAutoClose: true,
      loginOptions: {
        autoClose: true,
        height: 600,
        width: 600,
        top: 100,
        left: 100
      },
      softphone: {
        allowFramedSoftphone: true
      },
      pageOptions: {
        enableAudioDeviceSettings: true
      }
    };

    connect.core.initCCP(this.widgetTarget, opts);
    connect.agent(this.widgetReady.bind(this));
    connect.contact(this.contactReady.bind(this));

    window.addEventListener("beforeunload", this.disableAgent.bind(this));

  }

  async ensureValidUser() {
    const resp = await fetch(adminPhonesCheckUserPath(), {
      credentials: 'same-origin'
    });

    if (resp.status > 299) {
      throw "couldn't ensure user configuration";
    }

    const data = await resp.json();
    const activeQueues: number[] = data["current_routing_profile_cities"];
    const dedicated = data["dedicated"] === true;
    this.updateRoutingCities(activeQueues, dedicated);
  }

  dedicatedToggled(event: InputEvent) {
    if ((event.currentTarget as HTMLInputElement).checked) {
      this.routingAreaTargets.forEach(t => t.disabled = t.checked = true);
    } else {
      this.routingAreaTargets.forEach(t => t.disabled = t.checked = false);
    }
  }

  updateRoutingCities(activeQueues: number[], dedicated: boolean) {
    this.routingAreaTargets.forEach(city => {
      if (activeQueues.includes(parseInt(city.value)) === true) {
        city.setAttribute("checked", "checked");
      } else {
        city.removeAttribute(("checked"));
      }
    });

    this.dedicatedTarget.checked = dedicated;
  }

  widgetReady(agent: connect.Agent) {
    this.spinnerTarget.classList.add("is-hidden");
    this.widgetTarget.classList.remove("is-hidden");
    if (this.hasWidgetToggleIconTarget)
      this.widgetToggleIconTarget.classList.remove("has-text-grey");
    this.citiesToggleTarget.classList.remove("is-hidden");
    this.agentStatusToggleTarget.classList.remove("is-hidden");

    this.phoneAgent = agent;

    this.phoneAgent.onStateChange(this.onStateChange.bind(this));
  }

  toggleAgent() {
    if (this.phoneAgent === undefined) return;

    const states = this.phoneAgent.getAgentStates();
    const currentState = this.phoneAgent.getState();
    const newStateType = currentState.type == "offline" ? "routable" : "offline";
    const newState = states.find(s => s.type == newStateType);

    if (newState !== undefined)
      this.phoneAgent.setState(newState, {}, { enqueueNextState: true });
  }

  disableAgent(event: Event) {
    if (this.phoneAgent === undefined) return;

    const states = this.phoneAgent.getAgentStates();
    const offlineState = states.find(s => s.type === "offline");

    if (this.phoneAgent.getState().type !== "offline" && offlineState !== undefined) {
      event.preventDefault();

      this.phoneAgent.setState(offlineState, {
        failure() { event.stopPropagation(); }
      });
    }
  }

  contactReady(contact: connect.Contact) {
    contact.onIncoming(this.contactIncoming.bind(this));
    contact.onConnecting(this.contactIncoming.bind(this));

    contact.onMissed(this.contactMissed.bind(this));
    contact.onAccepted(this.contactAccepted.bind(this));
    contact.onDestroy(this.contactCompleted.bind(this));

    if (!contact.isInbound() && this.currentOrderID) {
      this.updateConnectionAttributes(contact.contactId, this.currentOrderID);

      this.currentOrderID = null;
    }
  }

  async updateConnectionAttributes(contactId: string, orderID: string) {
    const resp = await fetch(adminPhonesUpdateConnectContactAttributesPath(), {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ contact_id: contactId, attributes: { orderID: orderID } }),
    });

    if (resp.status > 299) {
      throw "Couldn't update contact attributes.";
    }
  }

  contactIncoming(contact: connect.Contact) {
    if (!contact.isInbound()) { return; }

    const conn = contact.getActiveInitialConnection();
    const endpoint = conn?.getEndpoint();

    const attrs = contact.getAttributes();
    if (attrs["orderID"]) {
      this.showOrderInfo(attrs["orderID"].value);
    }

    if (endpoint?.type === "phone_number") {
      this.startNotifyingOfCall();
    }
  }

  contactMissed(_: connect.Contact) {
    this.stopNotifyingOfCall();
  }

  contactAccepted(_: connect.Contact) {
    this.stopNotifyingOfCall();
  }

  contactCompleted(_: connect.Contact) {
    this.hideOrderInfo();
  }

  onBroadcastMessage(ev: MessageEvent<PhoneWidgetMessage>) {
    switch (ev.data.type) {
      case "startNotifyingOfCall":
        this.showIncomingCall();
        break;

      case "stopNotifyingOfCall":
        this.hideIncomingCall();
        break;

      case "initiateCall":
        this.doCall(ev.data.phNumber, ev.data.orderID);
        break;
    }
  }

  startNotifyingOfCall() {
    this.broadcastChannel?.postMessage({ type: "startNotifyingOfCall" });
    this.showIncomingCall();
  }

  stopNotifyingOfCall() {
    this.broadcastChannel?.postMessage({ type: "stopNotifyingOfCall" });
    this.hideIncomingCall();
  }

  showIncomingCall() {
    if (this.hasWidgetToggleIconTarget)
      this.widgetToggleIconTarget.classList.add("blob");
  }

  hideIncomingCall() {
    if (this.hasWidgetToggleIconTarget)
      this.widgetToggleIconTarget.classList.remove("blob");
  }

  showOrderInfo(orderID: string) {
    const linkEl = this.orderInfoIconTarget.querySelector("a");
    if (linkEl !== null) {
      linkEl.href = adminOrderPath(orderID);

      if (orderID && !isNaN(+orderID)) {
        window.open(linkEl.href, "_blank");
      }
    }

    this.orderInfoIconTarget.classList.remove("is-hidden");
  }

  hideOrderInfo() {
    this.orderInfoIconTarget.classList.add("is-hidden");
  }

  toggleOrderInfoDropdown() {
    this.orderInfoIconTarget.classList.toggle("is-active");
  }

  call(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.target === null) { return; }

    const el: HTMLAnchorElement = event.target as HTMLAnchorElement;
    const phNumber = el.getAttribute("data-number");
    const orderID = el.getAttribute("data-order-id")

    if (phNumber === null || phNumber === "") { return; }

    if (this.phoneAgent === undefined) {
      this.broadcastChannel?.postMessage({ type: "initiateCall", phNumber: phNumber, orderID: orderID });
    } else {
      this.doCall(phNumber, orderID);
    }
  }

  doCall(phNumber: string, orderID: string | null) {
    if (this.phoneAgent == null) return;
    // E.164 is the name of the number format AWS Connect expects. It's basically
    // drop the leading zeros and all other formatting characters, then prepend
    // the country code, which is what is hackly implemented below.
    // NOTE. We'll certainly break the if number entered is already beginning with
    // country code.
    const unformattedNumber = phNumber.replace(/[ -]/, "").replace(/^0/, "");
    const isE164 = !!unformattedNumber.match(/\+\d+/)
    const e164normalisedNumber = isE164 ? unformattedNumber : `+64${unformattedNumber}`;
    const endpoint = connect.Endpoint.byPhoneNumber(e164normalisedNumber);

    this.currentOrderID = orderID;

    this.phoneAgent.connect(endpoint, {
      failure: (err) => {
        console.log("Failed to connect:", err);
        this.currentOrderID = null;
      }
    });

    this.showPhoneWidgetDropdown();
  }

  onStateChange(stateChange: connect.AgentStateChange) {
    this.availabilityTarget.innerText = stateChange.newState;
  }
}
