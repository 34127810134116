import { Controller } from "@hotwired/stimulus";

type Event = {
  target: HTMLInputElement;
}

export default class extends Controller {
  static targets = [ "input" ]

  declare inputTargets : HTMLInputElement[];

  track(event: Event) {
    const target = event.target;
    if (target.defaultChecked != target.checked || target.defaultValue != target.value) {
      target.dataset.hasChanged = "1";
    } else {
      delete target.dataset.hasChanged;
    }
  }
}
