import { Controller } from "@hotwired/stimulus";

export default class PermissionsOverlayController extends Controller {
  static targets = ["overlay"];

  declare overlayTarget : HTMLElement;

  open() {
    this.overlayTarget.classList.add("is-active");
  }

  close(){
    this.overlayTarget.classList.remove("is-active");
  }
}
