import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["area", "restaurant"];

  declare areaTarget : HTMLSelectElement;
  declare restaurantTargets : [HTMLOptionElement];
  declare hasRestaurantTarget : boolean;

  connect() {
    this.filterRestaurantsByArea()
  }

  filterRestaurantsByArea() {
    if (this.hasRestaurantTarget !== true) {
      return;
    }

    const selectedArea = this.selectedArea();
    for (const option of this.restaurantTargets) {
      if (
        selectedArea === "" ||
        (selectedArea !== "" && option.getAttribute("data-area-id") == selectedArea)) {
          option.removeAttribute("hidden");
          option.removeAttribute("disabled");
      } else {
          option.setAttribute("hidden", "hidden");
          option.setAttribute("disabled", "disabled");
      }
    }
  }

  selectedArea() : string {
    const el : HTMLOptionElement | null = this.areaTarget.options[this.areaTarget.selectedIndex];
    if (el !== null) {
      return el.value;
    } else {
      return "";
    }
  }
}
