import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['recurringFields', 'recurringStartDateLabel', 'nonRecurringStartDateLabel', 'recurringCheckbox']

  declare recurringStartDateLabelTarget: HTMLLabelElement;
  declare nonRecurringStartDateLabelTarget: HTMLLabelElement;
  declare recurringFieldsTarget: HTMLDivElement;
  declare recurringCheckboxTarget: HTMLInputElement;

  declare hasNonRecurringStartDateLabelTarget: boolean;
  declare hasRecurringStartDateLabelTarget: boolean;
  declare hasRecurringFieldsTarget: boolean;

  connect(): void {
    if (this.recurringCheckboxTarget.checked)
      this.toggleRecurringFields();

    super.connect();
  }

  toggleRecurringFields(): void {
    if (!this.hasRecurringFieldsTarget || !this.hasRecurringStartDateLabelTarget || !this.hasNonRecurringStartDateLabelTarget) {
      throw "Missing field targets";
    }

    this.nonRecurringStartDateLabelTarget.classList.toggle("is-hidden")
    this.recurringStartDateLabelTarget.classList.toggle("is-hidden")

    this.recurringFieldsTarget.classList.toggle("is-hidden");
  }
}
