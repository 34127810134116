export default class Signal<T> {
  private handlers: Array<(data: T) => void> = [];

  public addListener(handler: (data: T) => void): void {
    this.handlers.push(handler);
  }

  public removeListener(handler: (data: T) => void): void {
    this.handlers = this.handlers.filter(h => h !== handler);
  }

  public notifyListeners(data: T): void {
    this.handlers.slice(0).forEach(h => h(data));
  }
}
