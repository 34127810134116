import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["requiredField", "regexField"];

  declare requiredFieldTargets : HTMLInputElement[];
  declare regexFieldTargets : HTMLInputElement[];

  validateFields(event: Event) : void {
    this.clearFieldErrors();

    for (const field of this.requiredFieldTargets) {
      const visible = field.offsetWidth > 0 || field.offsetHeight;

      if (visible === true) {
        const validator = field.type;
        if (validator == "checkbox") {
          this.validateChecked(field, event)
        } else if (validator == "select" || validator == "select-one" ) {
          this.validateSelected(field, event)
        } else if (validator == "textarea" || validator == "text") {
          this.validateTextEntered(field, event)
        }
      }
    }

    for (const field of this.regexFieldTargets) {
      const visible = field.offsetWidth > 0 || field.offsetHeight;
      if (visible === true) {
        this.validateRegex(field, event)
      }
    }
  }

  validateChecked(field: HTMLInputElement, event: Event) : boolean {
    if (field.checked != true) {
      this.displayFieldErrors(field, "Please check an option", event);
    }
    return true;
  }

  validateSelected(field: HTMLInputElement, event: Event) : boolean {
    if (field.value == "") {
      this.displayFieldErrors(field, "Please select an option", event);
    }
    return true;
  }

  validateTextEntered(field: HTMLInputElement, event: Event) : boolean {
    if (field.value == "") {
      this.displayFieldErrors(field, "Please enter some text", event);
    }
    return true;
  }

  validateRegex(field: HTMLInputElement, event: Event) : boolean {
    if (field.value == "")
      return true

    const validRegex = field.dataset.validregex
    if (validRegex != undefined){
      const regexp = RegExp(validRegex);

      if (regexp.test(field.value)){
        return true
      } else {
        this.displayFieldErrors(field, "Please check this is in the valid format", event);
      }
    } else {
      this.displayFieldErrors(field, "Please check this is in the valid format", event);
    }

    return false;
  }

  abort(event: Event) : void {
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  displayFieldErrors(field: HTMLInputElement, message: string, event: Event) : boolean {
    this.abort(event);
    field.scrollIntoView({ behavior: "smooth", block: "center" } );
    field.classList.add("is-danger");
    field.insertAdjacentHTML("afterend", `<p class='help is-danger'>${message}</p>`);
    return false;
  }

  clearFieldErrors() : void {
    this.requiredFieldTargets.forEach(field => {
      field.classList.remove("is-danger");
    });
    this.removeElementsByClass("help is-danger")
  }

  removeElementsByClass(className :string) : void {
    const elements = document.getElementsByClassName(className);
    if (elements != null){
    while (elements.length > 0){
      if (elements[0].parentNode != null)
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  }
}
