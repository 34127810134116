import { Dashboard, Delivery, DriverTrackingUpdate, DeliveryTrackingStep } from "src/admin/dispatch/model";

import MapPath from "src/map_path";

import driverMarkerImage from 'images/admin/map/driver-marker-icon.svg';
import driverMarkerStaleImage from 'images/admin/map/driver-marker-icon-stale.svg';
import driverMarkerPrimaryImage from 'images/admin/map/driver-marker-icon-primary.svg';
import driverMarkerPrimaryStaleImage from 'images/admin/map/driver-marker-icon-primary-stale.svg';
import storeMarkerImage from 'images/admin/map/restaurant_pinlet-2-medium.png'; // borrowed from google maps itself
import storeMarkerOutsideImage from 'images/admin/map/restaurant_pinlet-2-medium-outside.png';
import dropoffAddressMarkerIconImage from 'images/map/delivery-address-marker-icon.svg';

export interface StoreInfo {
  id: number;
  name: string;
  latitude: string;
  longitude: string;
  pickup_zone_id: number;
}

export default class {
  readonly LOCATION_STALE_AFTER_MILLISECONDS = 60*1000;
  readonly DROPOFF_PATH_COLOR = "rgb(255, 20, 20)";
  readonly PICKUP_PATH_COLOR = "rgb(255, 161, 0)";

  readonly mapTarget: HTMLElement;
  readonly lastUpdateTimeTarget?: HTMLElement;
  readonly compact: boolean;

  readonly map: google.maps.Map;
  readonly storeMarkerIcon: google.maps.Icon;
  readonly storeMarkerOutsideIcon: google.maps.Icon;
  readonly driverMarkerIcon: google.maps.Icon;
  readonly driverMarkerStaleIcon: google.maps.Icon;
  readonly driverMarkerPrimaryIcon: google.maps.Icon;
  readonly driverMarkerPrimaryStaleIcon: google.maps.Icon;
  readonly dropoffAddressMarkerIcon: google.maps.Icon;
  readonly infoWindow: google.maps.InfoWindow;

  readonly storeMarkers = new Map<number, google.maps.Marker>();
  readonly dropoffMarkers = new Map<number, google.maps.Marker>();
  readonly driverMarkers = new Map<number, google.maps.Marker>();
  readonly driverRouteSteps = new Map<string, google.maps.Polyline>();
  readonly driverTrackingUpdates = new Map<number, DriverTrackingUpdate>();

  readonly refreshTimer: number;

  readonly handleDashboardUpdate = this.dashboardUpdated.bind(this);
  readonly handleDriverTrackingUpdate = this.driverTrackingUpdate.bind(this);

  focussedPickupZone?: number;
  focussedDriver?: number;
  keepFocussedDriverInView = false;
  draggingMap = false;
  fitDrivers = true;
  deliveries: Delivery[] = [];
  focussedDeliveries: Delivery[] = [];

  constructor(
    mapTarget: HTMLElement,
    lastUpdateTimeTarget: HTMLElement | undefined,
    compact: boolean,
    stores: StoreInfo[],
  ) {
    this.mapTarget = mapTarget;
    this.lastUpdateTimeTarget = lastUpdateTimeTarget;
    this.compact = compact;

    this.map = new google.maps.Map(mapTarget, {
      center: { lat: 0, lng: 0 },
      zoom: 1,
      styles: [
        // turn off all markers to reduce clutter, particularly from irrelevant businesses,
        // then turn park names back on to help people orient
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [{ "visibility": "off" }]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels",
          "stylers": [{ "visibility": "on" }]
        },
        // make arterials faded rather than the standard yellow so the driver routes are clearer
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [{ "saturation": -100 }]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [{ "color": "#AAAAAA" }]
        },
      ],
      mapTypeControl: !compact,
      streetViewControl: !compact,
      fullscreenControl: !compact,
    });

    this.storeMarkerIcon = { url: storeMarkerImage, scaledSize: new google.maps.Size(17, 24) };
    this.storeMarkerOutsideIcon = { url: storeMarkerOutsideImage, scaledSize: new google.maps.Size(17, 24) };
    this.driverMarkerIcon = { url: driverMarkerImage, scaledSize: new google.maps.Size(36, 36), anchor: new google.maps.Point(18, 18) };
    this.driverMarkerStaleIcon = { url: driverMarkerStaleImage, scaledSize: new google.maps.Size(36, 36), anchor: new google.maps.Point(18, 18) };
    this.driverMarkerPrimaryIcon = { url: driverMarkerPrimaryImage, scaledSize: new google.maps.Size(36, 36), anchor: new google.maps.Point(18, 18) };
    this.driverMarkerPrimaryStaleIcon = { url: driverMarkerPrimaryStaleImage, scaledSize: new google.maps.Size(36, 36), anchor: new google.maps.Point(18, 18) };
    this.dropoffAddressMarkerIcon = { url: dropoffAddressMarkerIconImage, scaledSize: new google.maps.Size(24, 24), anchor: new google.maps.Point(12, 12) };

    const mapBounds = new google.maps.LatLngBounds(); // empty to start with

    for (const store of stores) {
      const position = { lat: parseFloat(store.latitude), lng: parseFloat(store.longitude) }

      const marker = new google.maps.Marker({
        map: this.map,
        position: position,
        zIndex: 1,
        title: store.name,
        icon: this.storeMarkerIcon,
      });
      marker.set("pickupZoneId", store.pickup_zone_id);
      marker.set("infoURL", "/admin/driver_tracking_info/stores/" + store.id);
      marker.addListener("click", () => this.openMarkerInfoWindow(marker));
      this.storeMarkers.set(store.id, marker);

      mapBounds.extend(position);
    }

    this.map.fitBounds(mapBounds);

    this.infoWindow = new google.maps.InfoWindow();
    google.maps.event.addListener(this.map, "click", () => this.infoWindow.close());
    google.maps.event.addListener(this.map, "dragstart", () => this.draggingMap = true);
    google.maps.event.addListener(this.map, "dragend", () => this.draggingMap = false);
    google.maps.event.addListener(this.map, "bounds_changed", () => this.calculateKeepFocussedDriverInView());

    this.refreshTimer = window.setInterval(() => this.updateTimeSinceLastLocation(), 5000);
  }

  dispose(): void {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  dashboardUpdated(dashboard: Dashboard): void {
    const drivers = dashboard.driversActive();
    const driverIds = drivers.map((driver) => driver.id);

    // add markers for any missing drivers (but don't put them on the map till we know their position),
    // and update any existing
    drivers.forEach((driver) => {
      const marker = this.driverMarkers.get(driver.id) ?? this.addDriverMarker(driver.id);
      marker.setTitle(driver.name);
      marker.set("areaId", driver.areaId);
      const trackingUpdate = this.driverTrackingUpdates.get(driver.id);
      if (trackingUpdate) this.placeDriverMarker(trackingUpdate);
    });

    // remove all drivers no longer active
    this.driverMarkers.forEach((marker, driverId) => {
      if (marker.get("areaId") == dashboard.areaId && !driverIds.includes(driverId)) {
        this.removeDriverMarker(driverId);
      }
    });

    this.deliveries = dashboard.deliveries;
    this.updateFocussedDeliveryMarkers()
  }

  driverTrackingUpdate(update: DriverTrackingUpdate): void {
    this.driverTrackingUpdates.set(update.driverId, update);
    this.placeDriverMarker(update);
  }

  focusZone(pickupZoneId: number | undefined, includeDrivers: boolean): void {
    if (pickupZoneId) {
      this.focussedPickupZone = pickupZoneId;
      this.fitDrivers = false;
    } else if (includeDrivers) {
      this.focussedPickupZone = undefined;
      this.fitDrivers = true;
    } else {
      this.focussedPickupZone = undefined;
      this.fitDrivers = false;
    }

    const mapBounds = new google.maps.LatLngBounds();

    this.storeMarkers.forEach((marker) => {
      if (!this.focussedPickupZone || this.focussedPickupZone == marker.get("pickupZoneId")) {
        const position = marker.getPosition();
        if (position) mapBounds.extend(position);
        marker.setIcon(this.storeMarkerIcon);
        marker.setZIndex(1);
      } else {
        marker.setIcon(this.storeMarkerOutsideIcon);
        marker.setZIndex(0);
      }
    });

    if (this.fitDrivers) {
      this.driverMarkers.forEach((d) => {
        const position = d.getPosition();
        if (d.getMap() && position) {
          mapBounds.extend(position);
        }
      });
    }

    this.map.fitBounds(mapBounds);
  }

  focusDriver(driverId?: number): void {
    this.focussedDriver = driverId;
    this.keepFocussedDriverInView = true;

    this.driverMarkers.forEach((marker, driverId) => {
      this.styleDriverMarker(driverId, marker);

      if (driverId == this.focussedDriver) {
        this.scrollMarkerIntoView(marker);
      }
    });

    this.updateTimeSinceLastLocation();
    this.updateDriverRoute(driverId ? this.driverTrackingUpdates.get(driverId) : undefined);
    this.updateFocussedDeliveryMarkers()
  }

  private calculateKeepFocussedDriverInView(): void {
    if (this.focussedDriver) {
      const bounds = this.map.getBounds();
      const position = this.driverMarkers.get(this.focussedDriver)?.getPosition();
      if (bounds && position) {
        this.keepFocussedDriverInView = (bounds.contains(position));
      }
    }
  }

  addDriverMarker(driverId: number): google.maps.Marker {
    const marker = new google.maps.Marker();
    marker.set("infoURL", "/admin/driver_tracking_info/drivers/" + driverId);
    marker.addListener("click", () => this.openMarkerInfoWindow(marker));
    this.driverMarkers.set(driverId, marker);
    return marker;
  }

  removeDriverMarker(driverId: number): void {
    this.driverMarkers.get(driverId)?.setMap(null);
    this.driverMarkers.delete(driverId);
    this.driverTrackingUpdates.delete(driverId);
  }

  private placeDriverMarker(trackingUpdate: DriverTrackingUpdate): void {
    const marker = this.driverMarkers.get(trackingUpdate.driverId);
    if (!marker) return;

    marker.setPosition({ lat: trackingUpdate.latitude, lng: trackingUpdate.longitude });
    marker.set("lastMoved", trackingUpdate.receivedAt.getTime());
    this.styleDriverMarker(trackingUpdate.driverId, marker);
    if (!marker.getMap()) marker.setMap(this.map);

    if (trackingUpdate.driverId == this.focussedDriver) {
      if (this.keepFocussedDriverInView && !this.draggingMap) {
        this.scrollMarkerIntoView(marker);
      } else {
        this.calculateKeepFocussedDriverInView();
      }
      this.updateTimeSinceLastLocation();
      this.updateDriverRoute(trackingUpdate);
    }
  }

  private updateDriverRoute(trackingUpdate?: DriverTrackingUpdate) {
    const deliveryTrackingList = trackingUpdate?.deliveryTrackingList ?? [];
    const remainingKeys = new Set(this.driverRouteSteps.keys());
    let currentLeg = true;

    const addPath = (steps: DeliveryTrackingStep[], color: string) => {
      const encoded = steps.map((step) => step.points);
      const key = encoded.join('\xff');
      remainingKeys.delete(key);

      let polyline = this.driverRouteSteps.get(key);
      if (!polyline) {
        polyline = new google.maps.Polyline({
          map: this.map,
          strokeWeight: 0,
          clickable: false,
          path: MapPath.decode(encoded).points,
        });
        this.driverRouteSteps.set(key, polyline);
      }

      polyline.setOptions({
        strokeColor: color,
        strokeWeight: currentLeg ? 4 : 0,
        icons: currentLeg ? [] : [
          {
            icon: {
              path: "M 0,-0.4 0,0.4",
              scale: 4,
              strokeOpacity: 0.5,
            },
            offset: "6px",
            repeat: "8px",
          },
        ],
      })

      if (currentLeg && trackingUpdate) {
        polyline.setPath(MapPath.decode(encoded).trim(new google.maps.LatLng(trackingUpdate.latitude, trackingUpdate.longitude)));
      }

      currentLeg = false;
    };

    deliveryTrackingList.forEach((delivery) => {
      if (delivery.routeToPickup) addPath(delivery.routeToPickup, this.PICKUP_PATH_COLOR);
      if (delivery.routeToDropoff) addPath(delivery.routeToDropoff, this.DROPOFF_PATH_COLOR);
    });

    remainingKeys.forEach((key) => {
      const polyline = this.driverRouteSteps.get(key);
      polyline?.setMap(null);
      this.driverRouteSteps.delete(key);
    })
  }

  private updateFocussedDeliveryMarkers() {
    const focussedDeliveries = this.focussedDriver ? this.deliveries.filter((delivery) => delivery.driverId == this.focussedDriver) : this.deliveries;
    if (focussedDeliveries.length == this.focussedDeliveries.length && focussedDeliveries.every((d) => this.focussedDeliveries.find((d2) => d2.id == d.id))) return;
    this.focussedDeliveries = focussedDeliveries;

    const focussedStores = this.focussedDeliveries.map((delivery) => delivery.storeId);
    this.storeMarkers.forEach((marker, storeId) => {
      if (focussedStores.includes(storeId)) {
        marker.setMap(this.map);
      } else {
        marker.setMap(null);
      }
    });

    this.dropoffMarkers.forEach((marker) => marker.setMap(null));
    this.dropoffMarkers.clear();
    this.focussedDeliveries.forEach((delivery) => {
      const marker = new google.maps.Marker({
        map: this.map,
        zIndex: 1,
        position: { lat: delivery.dropoffLatitude, lng: delivery.dropoffLongitude },
        title: delivery.deliveryAddress,
        icon: this.dropoffAddressMarkerIcon,
      });
      this.dropoffMarkers.set(delivery.id, marker);
    });
  }

  private scrollMarkerIntoView(marker: google.maps.Marker): void {
    // pan the marker into view if it isn't already, but otherwise don't re-center the map
    const position = marker.getPosition();
    if (position) this.map.panToBounds(new google.maps.LatLngBounds(position, position), 80 /* pixels padding */);
  }

  private styleDriverMarker(driverId: number, marker: google.maps.Marker): void {
    const lastMoved = marker.get("lastMoved");
    const staleLocation = (lastMoved == undefined || lastMoved <= Date.now() - this.LOCATION_STALE_AFTER_MILLISECONDS);

    if (this.focussedDriver == driverId) {
      marker.setIcon(staleLocation ? this.driverMarkerPrimaryStaleIcon : this.driverMarkerPrimaryIcon);
      marker.setZIndex(11);
    } else {
      marker.setIcon(staleLocation ? this.driverMarkerStaleIcon : this.driverMarkerIcon);
      marker.setZIndex(10);
    }
  }

  private updateTimeSinceLastLocation() {
    if (this.lastUpdateTimeTarget && this.focussedDriver) {
      const marker = this.driverMarkers.get(this.focussedDriver);
      const lastUpdate = (marker?.get("lastMoved") as number);

      if (lastUpdate != null) {
        const elapsedSeconds = (Date.now() - lastUpdate)/1000;

        if (elapsedSeconds < 20) {
          this.lastUpdateTimeTarget.innerText = 'Last location ping: just now';
        } else if (elapsedSeconds < 60) {
          this.lastUpdateTimeTarget.innerText = 'Last location ping: in the last minute';
        } else if (elapsedSeconds < 120) {
          this.lastUpdateTimeTarget.innerText = 'Last location ping: 1 minute ago';
        } else {
          this.lastUpdateTimeTarget.innerText = `Last location ping: ${Math.round(elapsedSeconds/60)} minutes ago`;
        }
      } else {
        this.lastUpdateTimeTarget.innerText = '';
      }
    }

    this.driverMarkers.forEach((marker, driverId) => {
      this.styleDriverMarker(driverId, marker);
    });
  }

  private async openMarkerInfoWindow(marker: google.maps.Marker) {
    const infoURL = marker.get("infoURL");

    if (infoURL != null) {
      const response = await fetch(infoURL, { credentials: 'same-origin' });

      if (response.status == 200) {
        this.infoWindow.setContent(await response.text());
        this.infoWindow.setOptions({ disableAutoPan: false }); // we want the auto-pan behavior when they actually click the marker to open the window
        this.infoWindow.open(this.map, marker);
        this.infoWindow.setOptions({ disableAutoPan: true }); // but not every time the marker moves, which happens by default too
      }
    }
  }
}