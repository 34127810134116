import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submitted = false;

  connect(): void {
    window.addEventListener('submit', () => {
      // Prevent unsaved changes warning on submit
      this.submitted = true;
    })

    window.addEventListener('beforeunload', (event) => {
      const permissionToggles = Array.from(document.getElementsByTagName("input")).filter((input) => input.name == "permissions[]" || input.name == "attributes[]")

      if (!this.submitted && permissionToggles.some((toggle) => toggle.defaultChecked != toggle.checked)) {
        // Most browsers will just use a default message anyway
        const confirmationMessage = "You still have unsaved permission changes";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });
  }

  toggleCategoryExpand(event: PointerEvent & { params: { category: string } }): void {
    const { category } = event.params;

    const collapsibleCategoryElement = document.getElementById(category);
    if (collapsibleCategoryElement == null) { return; }

    collapsibleCategoryElement.classList.toggle("is-hidden");
  }
}
