import { Controller } from "@hotwired/stimulus";

export default class AutocompleteJumpController extends Controller {
  static targets = ["srcValue"];

  declare srcValueTarget : HTMLInputElement;

  focus_click(ev: MouseEvent & { target: HTMLInputElement }) : void {
    ev.preventDefault();

    ev.target.focus();
    ev.target.select();
  }

  jump() : void {
    const pattern = this.data.get("redirect-pattern");

    if (pattern === null || this.srcValueTarget.value === "")
      return;

    const replaced = pattern.replace(/\{\}/, this.srcValueTarget.value);
    window.location.href = replaced;
  }
}
