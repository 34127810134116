import { Controller } from "@hotwired/stimulus";
import { LinearClient, LinearError } from '@linear/sdk'

const softwareTeamUUID = "b4efea99-19d4-4930-b83e-d3273aa9b1ef";

export default class LinearController extends Controller {
  static targets = ["modal", "ticketTypeSelect", "ticketDescription", "ticketTitle", "ticketCard", "adminDetails", "ticketErrorText", "submitButton", "ticketAttachment"];

  declare modalTarget : HTMLElement;
  declare ticketTypeSelectTarget : HTMLSelectElement;
  declare ticketDescriptionTarget: HTMLTextAreaElement;
  declare ticketTitleTarget: HTMLTextAreaElement;
  declare ticketCardTarget : HTMLElement;
  declare adminDetailsTarget : HTMLInputElement;
  declare ticketAttachmentTarget : HTMLInputElement;
  declare ticketErrorTextTarget : HTMLElement;
  declare submitButtonTarget: HTMLButtonElement;

  openModal() {
    this.modalTarget.classList.add("is-active");
  }

  closeModal(){
    this.modalTarget.classList.remove("is-active");
    this.ticketTypeSelectTarget.value = "";
    this.ticketAttachmentTarget.value = "";
    this.toggleTicketType();
  }

  toggleTicketType(){
    const ticketType = this.ticketTypeSelectTarget.value

    switch (ticketType) {
      case "feature":
        this.ticketDescriptionTarget.placeholder = "Describe the problem this feature request would solve"
        this.ticketCardTarget.classList.remove("is-hidden")
        break;
      case "bug":
        this.ticketDescriptionTarget.placeholder = "Describe the issue you are having"
        this.ticketCardTarget.classList.remove("is-hidden")
        break;
      default:
        this.ticketCardTarget.classList.add("is-hidden")
        this.ticketDescriptionTarget.placeholder = ""
        this.ticketDescriptionTarget.value = "";
        this.ticketTitleTarget.value = "";
        this.submitButtonTarget.disabled = false;
        this.submitButtonTarget.classList.remove("is-loading")
        this.ticketAttachmentTarget.value = ""
        break;
    }
  }

  async convert2DataUrl(blobOrFile:File) {
    const reader = new FileReader()
    reader.readAsDataURL(blobOrFile)
    await new Promise<void>(resolve => reader.onload = () => resolve())
    return reader.result
  }

  async submitTicket() {
    this.ticketErrorTextTarget.classList.add("is-hidden");
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add("is-loading");
    const linearClient = new LinearClient({ apiKey: this.modalTarget.dataset["linearApiKey"] });
    const labels = (await (await linearClient.team(softwareTeamUUID)).labels()).nodes;
    const bugLabel = labels.find(l => l.name == "Bug");
    const featureLabel = labels.find(l => l.name == "Feature");

    try {
      let ticketLabels: string[] = []
      if (bugLabel != null && featureLabel != null) {
        ticketLabels = this.ticketTypeSelectTarget.value == "bug" ? [bugLabel.id] : [featureLabel.id];
      }
        const title = this.ticketTitleTarget.value;
        const attachment = this.ticketAttachmentTarget?.files?.[0];
        let description = this.ticketDescriptionTarget.value.concat(`\n` + this.adminDetailsTarget.value)
        if (attachment) {
          description = description.concat(`\n![image text](${await this.convert2DataUrl(attachment)})`)
        }
        await linearClient.issueCreate({ teamId: softwareTeamUUID, title: title, description: description, labelIds: ticketLabels });
      this.closeModal();
    } catch (error) {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.classList.remove("is-loading")
      this.ticketErrorTextTarget.classList.remove("is-hidden");

      if (error instanceof LinearError)
        this.ticketErrorTextTarget.innerHTML = error.message;
    }
  }
}
