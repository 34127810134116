export enum DriverShiftStatus {
  Active = "active",
  RosteredButInactive = "rostered_but_inactive",
  RosteredToStartSoon = "rostered_to_start_soon",
  Inactive = "inactive",
  Unavailable = "unavailable",
  Onboarding = "onboarding",
  Offboarding = "offboarding",
  Removed = "removed",
}
