import * as Sentry from "@sentry/browser";
import { GoogleAutocompleteResolver } from "../../src/google_autocomplete_resolver";
import MapSuburbsController, { SuburbListing } from "./map_suburbs_controller";

import {
  adminMapBlockPath,
  findAdminMapBlocksPath,
} from 'src/routes';

interface MapBlockListing {
  id: number;
  suburb_id?: number;
  boundaries: string[];
}

interface MapBlockBounds {
  id: number;
  north: number;
  east: number;
  south: number;
  west: number;
}

export default class extends MapSuburbsController {
  static targets = ["map", "searchInput", "suburbSelect"];

  declare suburbSelectTarget: HTMLSelectElement;
  declare hasSuburbSelectTarget: boolean;
  declare searchInputTarget?: HTMLInputElement;
  declare hasSearchInputTarget: boolean;

  csrfToken!: string;

  mapBlocks = new Map<number, MapBlockListing>();
  suburbs = new Map<number, SuburbListing>();
  placeBoundaries: string[] = [];

  // we can't initialize any Maps objects until it's loaded, and we need to run the Loader to do that
  infoWindow?: google.maps.InfoWindow;
  infoWindowOpen = false;
  autocomplete?: google.maps.places.Autocomplete;

  blockPolygons = new Map<number, google.maps.Polygon>();
  placeBoundaryPolylines: google.maps.Polyline[] = [];

  initialBlockId?: number;
  initialLatLng?: google.maps.LatLng;

  selectedBlock?: MapBlockListing;
  selectedPolygon?: google.maps.Polygon;
  selectedLatLng?: google.maps.LatLng;

  async connect(): Promise<void> {
    await super.connect();
    this.csrfToken = (document.querySelector("meta[name='csrf-token']") as HTMLMetaElement)?.content ?? "";
    this.initAutocomplete();
  }

  disconnect(): void {
    super.disconnect();

    this.mapBlocks.clear();
    this.suburbs.clear();

    this.blockPolygons.forEach((block) => block.setMap(null));
    this.blockPolygons.clear();

    this.placeBoundaryPolylines.forEach((polyline) => polyline.setMap(null));
    this.placeBoundaryPolylines = [];
    this.placeBoundaries = [];

    this.infoWindow = this.selectedPolygon = this.selectedBlock = this.autocomplete = undefined;
  }

  protected createMap(): void {
    super.createMap();

    if (!this.map) return; // never happens

    this.infoWindow = new google.maps.InfoWindow();

    google.maps.event.addListener(this.infoWindow, "closeclick", () => {
      this.infoWindowOpen = false;
    });

    google.maps.event.addListener(this.map, "click", () => {
      this.infoWindow?.close()
      this.infoWindowOpen = false;

      if (this.selectedPolygon && this.selectedBlock) {
        this.setBlockStyle(this.selectedPolygon, this.selectedBlock, false);
        this.selectedBlock = this.selectedPolygon = this.selectedLatLng = undefined;
        this.updateHashFromState();
      }
    });

    google.maps.event.addListener(this.map, "bounds_changed", () => {
      this.updateHashFromState();

      // we get repeated events if the user is panning around, which queue up and cause both high load and
      // high latency for the latest bounds. debounce so we don't make more than one request per 50ms.
      this.loadDebounceTimer ??= window.setTimeout(() => {
        this.loadDebounceTimer = undefined;
        this.loadData();
      }, 50);
    });

    this.updateStateFromHash();
  }

  private updateHashFromState(): void {
    const bounds = this.map?.getBounds();
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      window.history.replaceState(undefined, "", `#n${ne.lat()},e${ne.lng()},s${sw.lat()},w${sw.lng()},b${this.selectedBlock?.id ?? ''},p0,lat${this.selectedLatLng?.lat() ?? ''},lng${this.selectedLatLng?.lng() ?? ''}`);
    }
  }

  private updateStateFromHash(): void {
    if (window.location.hash) {
      const match = window.location.hash.match(/#n([\d.-]+),e([\d.-]+),s([\d.-]+),w([\d.-]+)(?:,b(\d*))?(?:,p(\d+))?(?:,lat([\d.-]+),lng([\d.-]+))?/)
      if (match) {
        const ne = new google.maps.LatLng(parseFloat(match[1]), parseFloat(match[2]));
        const sw = new google.maps.LatLng(parseFloat(match[3]), parseFloat(match[4]));
        this.map?.fitBounds(new google.maps.LatLngBounds(sw, ne), parseInt(match[6] ?? '200'));

        this.initialBlockId = parseInt(match[5]);
        this.initialLatLng = match[7] && match[8] ? new google.maps.LatLng(parseFloat(match[7]), parseFloat(match[8])) : undefined;
      }
    }
  }

  protected async loadData(): Promise<void> {
    const path = this.data.get("path");
    const bounds = this.map?.getBounds();
    if (!path || !bounds) return;

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const pathWithBounds = `${path}?north=${ne.lat()}&east=${ne.lng()}&south=${sw.lat()}&west=${sw.lng()}`;

    const response = await fetch(pathWithBounds, { credentials: 'same-origin' });
    const json = await response.json();

    for (const suburb of json.suburbs) {
      this.suburbs.set(suburb.id, suburb);
    }

    for (const block of json.mapBlocks) {
      this.mapBlocks.set(block.id, block);
      this.renderBlock(block);
    }

    if (json.mapPlace) {
      this.placeBoundaries = json.mapPlace.boundaries;
      this.renderPlaceBoundary();
    }
  }

  private renderBlock(block: MapBlockListing) {
    if (this.blockPolygons.has(block.id)) return;
    const paths = block.boundaries.map((polyline) => this.decodePolyline(polyline));
    const polygon = new google.maps.Polygon({
      map: this.map,
      paths: paths,
    });
    if (block.id == this.initialBlockId) {
      this.setBlockStyle(polygon, block, true);
      this.blockSelected(polygon, block, this.initialLatLng);
    } else {
      this.setBlockStyle(polygon, block, false);
    }
    this.blockPolygons.set(block.id, polygon);

    polygon.addListener("click", (ev: google.maps.MapMouseEvent) => this.blockSelected(polygon, block, ev.latLng || undefined));
  }

  private renderPlaceBoundary() {
    const paths = this.placeBoundaries.map((polyline) => this.decodePolyline(polyline));
    this.placeBoundaryPolylines = paths.map((path) => new google.maps.Polyline({
      map: this.map,
      path: path,
      strokeOpacity: 0,
      icons: [
        {
          offset: "0",
          repeat: "7.5px",
          icon: {
            path: "M 0,-0.5 0,0.5",
            strokeOpacity: 1,
            scale: 2,
          },
        },
      ],
    }));
  }

  private setBlockStyle(polygon: google.maps.Polygon, block: MapBlockListing, selected: boolean): void {
    polygon.setOptions({
      fillColor: this.blockFillColor(block, selected),
      fillOpacity: !block.suburb_id ? 0.1 : (selected ? 0.6 : 0.4),
      strokeOpacity: selected ? 1 : 0.2,
      strokeColor: "rgba(50, 50, 50)",
      strokeWeight: 1,
    });
  }

  private blockFillColor(block: MapBlockListing, selected = false): string {
    if (block.suburb_id) {
      const suburb = this.suburbs.get(block.suburb_id);
      if (!suburb) return this.UNSERVICED_FILL_COLOR; // shouldn't ever happen
      return this.suburbFillColor(suburb)
    } else {
      return selected ? this.SELECTED_UNSERVICED_FILL_COLOR : this.UNSERVICED_FILL_COLOR;
    }
  }

  private blockSelected(polygon: google.maps.Polygon, block: MapBlockListing, clickLatLng?: google.maps.LatLng): void {
    if (!clickLatLng) return;

    if (this.selectedPolygon == polygon && this.infoWindowOpen) {
      this.infoWindow?.close();
      this.infoWindowOpen = false;
      this.selectedLatLng = undefined;
      this.updateHashFromState();
      return;
    }

    if (this.selectedPolygon && this.selectedBlock) {
      this.setBlockStyle(this.selectedPolygon, this.selectedBlock, false);
    }

    this.selectedBlock = block;
    this.selectedPolygon = polygon;
    this.selectedLatLng = clickLatLng;

    this.setBlockStyle(this.selectedPolygon, this.selectedBlock, true);

    this.openBlockInfo(this.selectedBlock, this.selectedLatLng);
    this.updateHashFromState();
  }

  private async openBlockInfo(block: MapBlockListing, clickLatLng: google.maps.LatLng): Promise<void> {
    const response = await fetch(adminMapBlockPath({ id: block.id }), { credentials: 'same-origin' });

    if (response.status == 200) {
      this.infoWindow?.setOptions({
        content: await response.text(),
        position: clickLatLng,
      });
      this.infoWindow?.open(this.map);
      this.infoWindowOpen = true;
    } else {
      Sentry.captureMessage(`MapBlocksController: displaying block info returned ${response.status}`);
    }
  }

  async submitBlockForm(ev: MouseEvent): Promise<void> {
    ev.preventDefault();

    if (this.hasSuburbSelectTarget && this.selectedPolygon && this.selectedBlock) {
      const updatedBlock = await this.saveBlockSuburbMapping(this.selectedBlock.id, this.suburbSelectTarget.value);
      this.selectedBlock.suburb_id = updatedBlock.suburb_id;
      this.setBlockStyle(this.selectedPolygon, this.selectedBlock, true);
    }

    this.infoWindow?.close();
    this.infoWindowOpen = false;
  }

  private async saveBlockSuburbMapping(blockId: number, suburbId: string): Promise<MapBlockListing> {
    const response = await fetch(adminMapBlockPath(blockId, { format: 'json' }), {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken,
       },
      body: JSON.stringify({ map_block: { suburb_id: suburbId } }),
    });
    if (response.status > 299) {
      Sentry.captureMessage(`MapBlocksController: updating block returned ${response.status}`);
      alert(await response.text());
    }

    const json = await response.json();
    return json["mapBlocks"][0];
  }

  private initAutocomplete(): void {
    if (this.hasSearchInputTarget && this.searchInputTarget) {
      this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, {
        fields: ['address_components', 'formatted_address', 'geometry', 'place_id', 'types'],
        bounds: GoogleAutocompleteResolver.defaultBounds(),
        strictBounds: true,
      });

      this.autocomplete.addListener("place_changed", this.placeChanged.bind(this));
    }
  }

  async placeChanged(): Promise<void> {
    if (this.hasSearchInputTarget && this.searchInputTarget && this.autocomplete) { // always true if this method is called
      const addressResolver = new GoogleAutocompleteResolver();
      const result = await addressResolver.resolveFromAutocomplete(this.searchInputTarget.value, this.autocomplete.getPlace());

      if (result) {
        this.searchInputTarget.value = result.address;

        const latlng = new google.maps.LatLng(result.latitude, result.longitude);
        this.map?.setCenter(latlng);

        const blockWithBounds = await this.findMapBlock(result.latitude, result.longitude);
        const polygon = blockWithBounds && this.blockPolygons.get(blockWithBounds.id);
        const block = blockWithBounds && this.mapBlocks.get(blockWithBounds.id);
        if (polygon && block) {
          this.blockSelected(polygon, block, latlng);
          const ne = new google.maps.LatLng(blockWithBounds.north, blockWithBounds.east);
          const sw = new google.maps.LatLng(blockWithBounds.south, blockWithBounds.west);
          this.map?.fitBounds(new google.maps.LatLngBounds(sw, ne), 200);
        }
      }
    }
  }

  async findMapBlock(latitude: number, longitude: number): Promise<MapBlockBounds | undefined> {
    const response = await fetch(findAdminMapBlocksPath({ format: 'json', latitude: latitude, longitude: longitude }), {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken,
       },
    });
    if (response.status == 404) {
      return undefined;
    }
    if (response.status > 299) {
      Sentry.captureMessage(`MapBlocksController: finding block returned ${response.status}`);
      alert(await response.text());
    }
    const json = await response.json();
    return (json["mapBlock"] as MapBlockBounds);
  }
}
