import { Controller } from "@hotwired/stimulus";

export default class UrlFragmentTabsController extends Controller {
  static targets = ["tabBody"];

  observer?: IntersectionObserver;

  declare tabBodyTargets: HTMLDivElement[];

  connect() : void {
    const initialPath = window.location.pathname;
    this.focusAndLoad(initialPath);
  }

  focusAndLoad(pathname: string) {
    for (const tabBody of this.tabBodyTargets) {
      if (tabBody.getAttribute("data-tab-href") == pathname) {
        tabBody.classList.remove("is-hidden");
        this.loadContentForTab(tabBody);
      } else {
        tabBody.classList.add("is-hidden");
      }
    }
  }

  focusTab(event: MouseEvent) {
    event.preventDefault();

    const aEl = event.currentTarget as HTMLAnchorElement;
    window.history.pushState({}, "", aEl.href);

    const newPath = new URL(aEl.href).pathname;
    this.focusAndLoad(newPath);

    return false;
  }

  async loadContentForTab(tab: HTMLElement) : Promise<void> {
    const src = tab.getAttribute("data-tab-src");
    if (!src)
      return;

    const resp = await fetch(src, {
      credentials: 'include',
    });
    const bodyContent = await resp.text();
    tab.innerHTML = bodyContent;
    tab.removeAttribute("data-tab-src");
  }
}
