import { Controller } from "@hotwired/stimulus";

import Modal from "src/admin/modal";

import { createNoteAdminDispatchDashboardStorePath } from 'src/routes';

export default class extends Controller {
  static targets = ["dropdown"];

  declare dropdownTarget: HTMLElement;
  declare hasDropdownTarget: boolean;

  openRestaurantModal() : void {
    this.toggleDropdown();

    Modal.show({
      title: "Create restaurant note",
      content: this.populateRestaurantContent(),
      onAccept: async (rootEl: HTMLElement) => await this.submitRestaurantModal(rootEl),
    }).catch(() => undefined);
  }

  async submitRestaurantModal(rootEl: HTMLElement) : Promise<void> {
    const note = rootEl.querySelector("#shiftReportTextarea") as HTMLTextAreaElement;
    const restaurantId = rootEl.querySelector("#shiftReportRestaurantSelect") as HTMLSelectElement;

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }
    const csrfToken = metaElement.content;

    const id = this.data.get("dashboard-id");
    if (id != undefined) {
      await fetch(createNoteAdminDispatchDashboardStorePath(id, restaurantId.value), {
        method: "POST",
        body: JSON.stringify({ note: note.value }),
        credentials: "include",
        headers: { "X-CSRF-Token": csrfToken, "Content-Type": "application/json" }
      });
    }

  }

  toggleDropdown() : void {
    if (this.hasDropdownTarget)
      this.dropdownTarget.classList.toggle("is-active");
  }

  private populateRestaurantContent() : HTMLElement {
    const rootEl = document.createElement("div");

    const noteField = document.createElement("div");
    noteField.classList.add("field")

    const noteControl = document.createElement("div");
    noteControl.classList.add("control");

    const noteLabel = document.createElement("label");
    noteLabel.classList.add("label");
    noteLabel.innerText = "Note";

    const textarea = document.createElement("textarea");
    textarea.id = "shiftReportTextarea";
    textarea.classList.add("textarea");
    textarea.setAttribute("data-form-validator-target", "requiredField");
    textarea.placeholder = "Note about what happened for the shift report - e.g. 'Driver was consistently running late all night'"

    noteControl.appendChild(noteLabel);
    noteControl.appendChild(textarea);
    noteField.appendChild(noteControl);
    rootEl.appendChild(noteField);

    const selectField = document.createElement("div");
    selectField.classList.add("field");

    const selectControl = document.createElement("div");
    selectControl.classList.add("control");

    const selectLabel = document.createElement("label");
    selectLabel.classList.add("label");
    selectLabel.innerText = "Restaurant";

    const select = document.createElement("select");
    select.id = "shiftReportRestaurantSelect";
    select.classList.add("select");
    select.setAttribute("data-form-validator-target", "requiredField")

    const restaurants = this.data.get("restaurants");
    if (restaurants != null){
      const r1 = JSON.parse(restaurants);

      const sortable = [];
      for (const k in r1) {
        sortable.push([k, r1[k]]);
      }

      sortable.sort(function(a, b) {
        return a[1].localeCompare(b[1], { sensitivity: 'base' });
      });

      sortable.forEach(element => {
        const option = document.createElement("option");
        option.text = element[1];
        option.value = element[0];
        select.add(option);
      });
    }

    selectControl.appendChild(selectLabel);
    selectControl.appendChild(select);
    selectField.appendChild(selectControl);
    rootEl.appendChild(selectField);


    return rootEl;
  }
}
