import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["amountIcon", "amountLabel", "typeSelect", "previewValue", "amountInput", "form", "menuItemCheckbox"];

  declare amountIconTarget : HTMLElement
  declare amountLabelTarget : HTMLElement
  declare formTarget : HTMLFormElement
  declare amountInputTarget: HTMLInputElement
  declare typeSelectTarget : HTMLSelectElement
  declare previewValueTargets : HTMLSpanElement[]
  declare menuItemCheckboxTargets : HTMLInputElement[]

  toggleAmountFields() : void {
    if (this.typeSelectTarget.value == "fixed") {
      this.amountIconTarget.innerHTML = "$"
      this.amountLabelTarget.innerHTML = "Amount"
    } else {
      this.amountIconTarget.innerHTML = "%"
      this.amountLabelTarget.innerHTML = "Percent"
    }
  }

  preview(event: Event) : void {
    event.preventDefault();
    const amount = parseFloat(this.amountInputTarget.value)
    const discountType = this.typeSelectTarget.value
    if (isNaN(amount)){ return }

    this.previewValueTargets.forEach(previewItem => {
      if (previewItem.dataset["menuItemPrice"] != undefined) {
        const currentPrice = parseFloat(previewItem.dataset["menuItemPrice"])
        const updatedPrice = this.calculateUpdatedPrice(currentPrice, amount, discountType)

        previewItem.innerHTML = "$" + updatedPrice.toFixed(1)
      }
    });
  }

  confirmApply(event: Event) : void {
    event.preventDefault();

    if (confirm("Are you sure you want to apply these updates?") === true) {
      this.formTarget.submit();
    }
  }

  selectAll(event: Event) : void {
    event.preventDefault()
    this.menuItemCheckboxTargets.forEach(checkbox => {
      checkbox.checked = true;
    });
  }

  deselectAll(event: Event) : void {
    event.preventDefault()
    this.menuItemCheckboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });
  }

  private calculateUpdatedPrice(currentPrice: number, amount: number, discountType: string) {
    if (discountType == "percentage") {
      return currentPrice + (currentPrice * (amount/100));
    } else {
      return currentPrice + amount;
    }
  }
}
