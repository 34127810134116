import { Controller } from "@hotwired/stimulus";

export default class IncidentController extends Controller {
  static targets = ["modal", "incidentErrorText", "submitButton", "search", "hidden", "participantList", "driverIds", "description", "externalParticipants", "approximateTime", "conditions", "subcategory", "location", "injuryCheckbox", "bodyPartCheckbox", "boardNotifiable", "date", "externalParticipantsContainer"];

  declare modalTarget : HTMLElement;
  declare incidentErrorTextTarget : HTMLElement;
  declare submitButtonTarget: HTMLButtonElement;
  declare searchTarget: HTMLInputElement;
  declare hiddenTarget: HTMLInputElement;
  declare participantListTarget: HTMLUListElement;
  declare driverIdsTarget: HTMLInputElement;

  declare descriptionTarget: HTMLInputElement;
  declare externalParticipantsTarget: HTMLInputElement;
  declare approximateTimeTarget: HTMLInputElement;
  declare conditionsTarget: HTMLInputElement;
  declare subcategoryTarget: HTMLSelectElement;
  declare locationTarget: HTMLSelectElement;
  declare injuryCheckboxTargets: HTMLInputElement[];
  declare bodyPartCheckboxTargets: HTMLInputElement[];
  declare boardNotifiableTarget: HTMLInputElement;
  declare dateTarget: HTMLInputElement;
  declare externalParticipantsContainerTarget: HTMLDivElement;

  openModal() {
    this.modalTarget.classList.add("is-active");
  }

  closeModal(){
    this.modalTarget.classList.remove("is-active");
    this.searchTarget.value = '';
    this.driverIdsTarget.value = '';
    this.participantListTarget.innerHTML = ''
    this.descriptionTarget.value = '';
    this.externalParticipantsTarget.value = '';
    this.approximateTimeTarget.value = '';
    this.conditionsTarget.value = '';
    this.subcategoryTarget.value = '';
    this.locationTarget.value = '';
    this.boardNotifiableTarget.value = '';
    this.dateTarget.value = '';
    this.externalParticipantsContainerTarget.classList.add("is-hidden");
    const radio_yes = document.getElementById("external_participants_true") as HTMLInputElement;
    const radio_no = document.getElementById("external_participants_false") as HTMLInputElement;
    radio_yes.checked = false;
    radio_no.checked = false;
    this.injuryCheckboxTargets.forEach((checkbox) => { checkbox.checked = false});
    this.bodyPartCheckboxTargets.forEach((checkbox) => { checkbox.checked = false});
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove("is-loading")
  }

  addParticipant(){
    if (this.hiddenTarget.value == '' || this.participantAlreadyExists(this.hiddenTarget.value)){
      return
    } else {
      const participant = document.createElement("div");
      participant.classList.add("participant", "list-item");
      participant.dataset["id"] = this.hiddenTarget.value;

      const content = ` 
        <p class="is-flex-grow-1">${this.searchTarget.value}</p>
        <div class="select is-small">
          <select data-form-validator-target="requiredField" data-action="change->incident#populateParticipantInvolvement">
            <option></option>
            <option value="cause">cause</option>
            <option value="affected">affected</option>
            <option value="observer">observer</option>
          </select>
        </div>

        <button class="button is-small is-align-items-end ml-1" data-action="click->incident#removeParticipant">
          <span class="icon">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      `

      participant.insertAdjacentHTML("afterbegin", content);
      this.participantListTarget.appendChild(participant);

      this.searchTarget.value = "";
      this.hiddenTarget.value = "";
    }
  }
  
  removeParticipant(event: MouseEvent){
    const target = event.target as HTMLElement;
    const participant = target.closest(".participant") as HTMLInputElement
    if (participant != undefined)
    participant.remove();
  }

  populateParticipantInvolvement(event: Event){
    const target = event.target as HTMLInputElement;
    const participant = target.closest(".participant") as HTMLInputElement
    if (participant != undefined)
      participant.dataset["involvement"] = target.value;
  }

  async submitIncident(){
    this.incidentErrorTextTarget.classList.add("is-hidden");
    this.incidentErrorTextTarget.innerHTML = "";
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add("is-loading")

    const arr : HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName("participant") as HTMLCollectionOf<HTMLInputElement>;

    const participants = Array.from(arr).map((el) => { 
      const driver_id = el.dataset["id"];
      const involvement = el.dataset["involvement"];
      return [driver_id, involvement];
    });

    const natureOfInjury : string[] = [];
    const bodyPart : string[] = []
    this.injuryCheckboxTargets.forEach((checkbox) => { 
      if (checkbox.checked)
        natureOfInjury.push(checkbox.value);
    });

    this.bodyPartCheckboxTargets.forEach((checkbox) => { 
      if (checkbox.checked)
        bodyPart.push(checkbox.value);
    });

    const bodyContent = { 
      "participants": participants,
      "description": this.descriptionTarget.value,
      "external_participants": this.externalParticipantsTarget.value,
      "approximate_time": this.approximateTimeTarget.value,
      "conditions": this.conditionsTarget.value,
      "subcategory_id": this.subcategoryTarget.value,
      "area_id": this.locationTarget.value,
      "nature_of_injury": natureOfInjury,
      "body_part": bodyPart,
      "board_notifiable": this.boardNotifiableTarget.checked,
      "date": this.dateTarget.value
    };

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }
    const csrfToken = metaElement.content;
    const url = this.submitButtonTarget.dataset["url"];

    if (url != undefined){
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Accepts": "application/json",
          "X-CSRF-Token": csrfToken
        },
        body: JSON.stringify(bodyContent)
      });

      if (response.status == 200){
        this.closeModal();
      } else {
        this.incidentErrorTextTarget.classList.remove("is-hidden");
        const json = await response.json();
        this.incidentErrorTextTarget.innerText = json["error"];
        this.incidentErrorTextTarget.scrollIntoView();
        this.submitButtonTarget.disabled = false;
        this.submitButtonTarget.classList.remove("is-loading")
      }
    } else {
      throw new Error("Could not load report incident url");
    }
  }

  showExternalParticipantsContainer(){
    this.externalParticipantsContainerTarget.classList.remove("is-hidden");
  }

  hideExternalParticipantsContainer(){
    this.externalParticipantsContainerTarget.classList.add("is-hidden");
  }

  private participantAlreadyExists(id: string){
    const arr : HTMLElement[] = Array.from(document.querySelectorAll(".participant"));
    return arr.some(el => el.dataset.id === id);
  }
}
