import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "fullName", "invoicingName" ];
  declare fullNameTarget: HTMLInputElement;
  declare invoicingNameTarget: HTMLInputElement;

  connect() {
    this.updatePlaceholders();
  }

  updatePlaceholders() {
    const nameParts = this.getNameParts();

    this.invoicingNameTarget.setAttribute("placeholder", nameParts.invoicingName);
  }

  updateFields() {
    const nameParts = this.getNameParts();

    this.invoicingNameTarget.value = nameParts.invoicingName;
  }

  getNameParts() {
    const name = this.fullNameTarget?.value;

    return {
      invoicingName: name || ""
    };
  }
}
