import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message", "nextButton"];

  declare messageTarget : HTMLElement;
  declare nextButtonTarget : HTMLElement;

  statusUrl : string | null = null;
  timer : number | null = null;

  connect() {
    this.statusUrl = this.data.get("status-url");

    this.queueRefresh();
  }

  queueRefresh() {
    if (this.statusUrl === null) { return; }

    if (this.timer !== null) {
      window.clearTimeout(this.timer);
    }
    this.timer = window.setTimeout(this.updateStatus.bind(this), 3000);
  }

  async updateStatus() {
    if (this.statusUrl === null) { return; }

    const resp = await fetch(this.statusUrl, {
      credentials: "include"
    });

    const json = (await resp.json()) as StatusResponse;

    if (json.enrollment_status == EnrollmentStatus.Enrolled) {
      this.messageTarget.classList.add("is-hidden");
      this.nextButtonTarget.classList.remove("is-hidden");
    } else {
      this.queueRefresh();
    }
  }
}

enum EnrollmentStatus { 
  Pending = "pending",
    Enrolled = "enrolled"
};

interface StatusResponse {
  enrollment_status: EnrollmentStatus;
  device_name: string | null;
}
