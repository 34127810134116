import React from "react";
import Modal from "src/admin/modal";

import { TimeSlot, ApiClientInterface } from "./model";

interface HeatMapProps {
  slots: TimeSlot[];
  apiClient: ApiClientInterface;
}

export default class HeatMap extends React.Component<HeatMapProps, {}> {
  render() {
    const timeSlotNodes = this.props.slots.map(timeSlot => {
      return (
        <TimeSlotNode
          key={timeSlot.time}
          apiClient={this.props.apiClient}
          timeSlot={timeSlot}
        />
      )
    });

    return (
      <div className="columns is-gapless is-mobile heatmap mb-4">
        {timeSlotNodes}
        <OtherTimeNode
          apiClient={this.props.apiClient}
        />
      </div>
    );
  }
}

interface TimeSlotNodeProps {
  timeSlot: TimeSlot;
  apiClient: ApiClientInterface;
}

class TimeSlotNode extends React.Component<TimeSlotNodeProps, {}> {
  async updatePanic() {
    const confirmMessage = `Are you sure you want to panic out ${this.props.timeSlot.time} pickups?`;

    if (confirm(confirmMessage) === true) {
      await this.props.apiClient.updatePanic(this.props.timeSlot.unformattedTime);
    }
  }

  render() {
    const classes = [ "pt-2", this.props.timeSlot.colour ];

    if (this.props.timeSlot.isOperatingTime) {
      classes.push(this.props.timeSlot.panicked ? "panicked" : "unpanicked");
    }
    else {
      classes.push("closed");
    }

    return (
      <div className="column has-text-centered has-background-white">
        <a className={classes.join(" ")} onClick={this.updatePanic.bind(this)}>
          {this.props.timeSlot.time}

          <span className="mt-1 py-1">
            {this.props.timeSlot.rating}
          </span>
        </a>
      </div>
    )
  }
}

interface OtherTimeNodeProps {
  apiClient: ApiClientInterface;
}

class OtherTimeNode extends React.Component<OtherTimeNodeProps, {}> {
  requestPanic() {
    const time = new Date();
    time.setTime((Math.round(time.getTime() / 300000) - 1) * 300000);

    const endTime = new Date();
    endTime.setTime(time.getTime() + 24*60*60*1000);

    const options = [];

    while (time < endTime) {
      time.setTime(time.getTime() + 300*1000);
      options.push(`<option value="${time.toISOString()}">${time.toLocaleString("en", { timeStyle: "short" })}</option>`);
    }

    // Insert a separator line after the time slots that are also visible on the heatmap
    //   determined by the loop count in timeSlotsVisibleOnHeatmap()
    options.splice(15, 0, `<option disabled="true">────────────</option>`);

    Modal.show({
      content: `
        <div class="field">
          <div class="control">
            <label class="label is-inline-block">Panic out pickups to</label>
            <div class="select is-inline-block not-top-aligned">
              <select data-form-validator-target="requiredField">
                ${options.join()}
              </select>
            </div>
          </div>
        </div>`,
      title: "Panic",
      onAccept: async (content: HTMLElement) => {
        const select = content.querySelector("select");
        if (select) this.props.apiClient.updatePanic(select.value);
      },
    }).catch(() => undefined);
  }

  render() {
    return (
      <div className="column has-text-centered has-background-white later">
        <a className="dash-time-slot clear-time-slot unpanicked" onClick={this.requestPanic.bind(this)}>
          <i className="fas fa-angle-double-right"></i>
        </a>
      </div>
    )
  }
}
