import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  disable(event: MouseEvent) {
    const inputTarget = event.target;
    if (inputTarget === null) return;

    if (inputTarget instanceof HTMLInputElement || inputTarget instanceof HTMLButtonElement) {
      const form = inputTarget.form
      if (form === null) return;

      if (form.checkValidity()) {
        inputTarget.disabled = true;

        form.submit();
      } else {
        form.reportValidity();
      }
    } else if (inputTarget instanceof HTMLAnchorElement) {
      // Visually mark as disabled
      inputTarget.setAttribute("disabled", "disabled");

      // Prevent second button click as well
      const targetLocation = inputTarget.href;
      inputTarget.href = "javascript: void(0)";
      window.location.href = targetLocation;
    }
  }
}
